import React, { useRef, useState, useEffect } from "react";
import $ from 'jquery'
import Footer from './Footer'
import { useLocation, Route, useHistory, useRouteMatch } from 'react-router-dom'
import BottomScrollListener from 'react-bottom-scroll-listener'
import Loading, { LoadingCenter } from './Loading'
import Modal from './CustomModal'
import { loader } from 'graphql.macro'
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { gqlErrorHandler, chkImageUrl, openMarket } from './Library'
import OrderDetail from "./OrderDetail";
import { Transition } from "react-transition-group";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const orderListGql = loader('../gql/order_list.gql')
const orderCancelGql = loader('../gql/order_cancel.gql')
const orderFinishGql = loader('../gql/order_finish.gql')
const orderDisplayGql = loader('../gql/order_display.gql')

export default function () {
	const match = useRouteMatch()
	return (
		<div id="wrap">
			<Route path={`${match.path}`}>
				<div id="header" className="subHeader2">
					<div className="header">
						<h1>주문내역</h1>
					</div>
				</div>

				<div id="container">
					<div id="contents" className="subCnts3">
						<div className="orderSection">
							<div className="myOrderState">
								<OrderList />
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</Route>
			<Route path={`${match.path}/:id`}>
				<OrderDetail />
			</Route>
		</div>
	)
}

function OrderList() {
	const doFetchMore = useRef(true)
	useEffect(() => {
		//기타버튼 레이어 show,hide
		$(document).on('click', function (e) {
			e.preventDefault();
			if (!$(e.target).hasClass('btnEtc')) {
				//외부클릭시모두닫기
				$('.btnEtc').removeClass('on').next().slideUp('fast');
			} else {
				//선택레이어토클
				$(e.target).toggleClass('on').next().slideToggle('fast');
				//다른레이어닫기
				$('.btnEtc').not($(e.target)).removeClass('on').next().slideUp('fast');
			}
		})
		return () => $(document).off('click')
	})
	const history = useHistory()
	const match = useRouteMatch()
	const location = useLocation()

	const [screenDim, setScreenDim] = useState(false);

	const [orderCancel] = useMutation(orderCancelGql, {
		onCompleted() { refetch(); setScreenDim(false) },
		onError(err) { gqlErrorHandler(err) }
	})
	const [orderFinish] = useMutation(orderFinishGql, {
		onCompleted() { refetch(); setScreenDim(false) },
		onError(err) { gqlErrorHandler(err) }
	})
	const [orderHidden] = useMutation(orderDisplayGql, {
		onCompleted() { refetch(); setScreenDim(false) },
		onError(err) { gqlErrorHandler(err) }
	})

	const values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		status: "PAY,WORKING,SHIPPING,FINISH,CANCEL",
		day: 180,
		first: 15,
	}
	const { error, data, fetchMore, refetch, networkStatus } = useQuery(orderListGql, { variables: values, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
	if (error) { gqlErrorHandler(error); return null; }
	if (!data) return <LoadingCenter />

	const handleFetchMore = async (fm, data) => {
		if (data.gongOrders.pageInfo.hasNextPage === false) return
		if (doFetchMore.current === false) return

		doFetchMore.current = false

		await fm({
			variables: {
				cursor: data.gongOrders.pageInfo.endCursor
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongOrders.edges;
				const pageInfo = fetchMoreResult.gongOrders.pageInfo;

				return newEdges.length
					? {
						gongOrders: {
							__typename: previousResult.gongOrders.__typename,
							edges: [...previousResult.gongOrders.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		})

		doFetchMore.current = true
	}
	const handleOrderCancel = (e, order) => {
		e.preventDefault()
		Modal.confirm({
			title: <>주문을 취소하시겠습니까?<br />취소 이후엔 수정이 불가능합니다.</>,
			okText: "확인",
			cancelText: "취소",
			onOk() {
				setScreenDim(true)
				const variables = {
					shopId: order.shop.pk,
					token: order.token,
				}
				orderCancel({ variables })
			}
		})
	}
	const handleOrderFinish = (e, order) => {
		e.preventDefault()
		Modal.confirm({
			title: <>주문하신 상품(음식)을 정말<br />받으셨습니까?</>,
			content: <span style={{ color: 'red' }}>*수취 확인 시 구매확정 처리 되며, 철회할 수 없습니다.</span>,
			okText: "확인",
			cancelText: "취소",
			onOk() {
				setScreenDim(true)
				const variables = {
					shopId: order.shop.pk,
					token: order.token,
				}
				orderFinish({ variables })
			}
		})
	}
	const handleOrderHidden = (e, order) => {
		e.preventDefault()
		Modal.confirm({
			title: <>선택한 주문내역을 삭제하시겠습니까?</>,
			content: <>삭제 후에는 복구할 수 없습니다.</>,
			okText: "확인",
			cancelText: "취소",
			onOk() {
				setScreenDim(true)
				const variables = {
					orderId: order.pk,
				}
				orderHidden({ variables })
			}
		})
	}
	/**
	 * 6시간 전후의 주문 css가 다름, ing ed로 나워서 처리.
	 * margin-left값 변경 시 base.css에서의 설정과 함께 고려하도록.
	 */
	const duration = 500 // 트랜지션 지속시간
	const defaultStyleCommon = { transition: `opacity ${duration}ms ease, margin-left ${duration}ms ease`, opacity: 0 } // 트랜지션 전 공통
	const defaultStyle = { // 트랜지션 전 나눔
		ing: { ...defaultStyleCommon, marginLeft: 46 },
		ed: { ...defaultStyleCommon, marginLeft: 30 }
	}

	const transitionStylesCommon = { opacity: 1 } // 트랜지션 후 공통
	const transitionStyles = { // 트랜지현 후 나눔
		ing: { entered: { ...transitionStylesCommon, marginLeft: 16 } },
		ed: { entered: { ...transitionStylesCommon, marginLeft: 0 } }
	}

	return (
		<>
			{(screenDim === true || networkStatus === NetworkStatus.refetch) && <ScreenDim /> /* 액션(삭제,완료,취소) 중 덮는 레이어 */}
			<div className="myOrderList">
				<ul>
					<BottomScrollListener onBottom={() => handleFetchMore(fetchMore, data)}>
						{data.gongOrders.edges.map((item) => {
							let step = 0
							if (item.node.orderStatus === 'PAY') step = 1
							if (item.node.orderStatus === 'WORKING') step = 2
							if (item.node.orderStatus === 'SHIPPING') step = 3
							if (item.node.orderStatus === 'FINISH') step = 4

							// const orderState = dayjs().isAfter(dayjs(item.node.orderAt, 'YYYY.MM.DD HH:mm').add(6, 'hour')) ? 'ed' : 'ing' // 6시간 이전만 카드뷰
							const orderState = (['CANCEL', 'FINISH'].includes(item.node.orderStatus) || item.node.statusDetail === 'FINISH_ACCEPT') ? 'ed' : 'ing' // 시장서비스는 취소,배달완료,수취확인 시 리스트뷰로. issues/2425

							return (
								<Transition key={item.node.pk} in appear timeout={duration}>
									{state => {
										const style = { ...defaultStyle[orderState], ...transitionStyles[orderState][state] }
										return (
											<li className={orderState === 'ing' ? 'orderState' : ''} key={item.node.pk} style={style}>
												<div className="box">
													<div className="img" onClick={(e) => openMarket(item.node.shop.pk, e)}>
														{chkImageUrl(item.node.shop.img) && <img src={chkImageUrl(item.node.shop.img)} alt="" />}
													</div>
													<div className="cnt">
														<div className="state">
															<span>{dayjs(item.node.orderAt, 'YYYY.MM.DD HH:mm').format('YY-MM-DD')}</span>
															{item.node.orderStatus === 'CANCEL' && <span className="now cancle">취소완료</span>}
															{item.node.orderStatus === 'FINISH' && <span className="now">배달완료</span>}
														</div>
														<p>{item.node.shop.name}</p>
														<span className="menu">{item.node.goodsName}</span>
														<a href="#this" className="txtHide btnEtc">기타</a>
														<div className="layerEtc">
															<a href="#this" onClick={(e) => openMarket(item.node.shop.pk, e)}>시장보기</a>
															{orderState !== 'ing' && <a href="#this" onClick={(e) => handleOrderHidden(e, item.node)}>주문내역 삭제</a>}
														</div>
													</div>
												</div>
												<div className="btnsTwice">
													<span><a href="#this" onClick={(e) => { e.preventDefault(); history.push(`${match.url}/${item.node.pk}${location.search}`) }} className="btnTy5">주문 상세</a></span>
													{item.node.orderStatus === 'PAY' &&
														<span><a href="#this" onClick={(e) => handleOrderCancel(e, item.node)} className="btnTy7">주문 취소</a></span>
													}
													{item.node.statusDetail !== 'FINISH_ACCEPT' && ['SHIPPING', 'FINISH'].includes(item.node.orderStatus) &&
														<span><a href="#this" onClick={(e) => handleOrderFinish(e, item.node)} className="btnTy5">수취 확인</a></span>
													}
												</div>
												{orderState === 'ing' && item.node.orderStatus !== 'CANCEL' &&
													<div className="deliveryState state1">
														<p>
															{item.node.visitAt && (item.node.orderStatus === 'WORKING' || item.node.orderStatus === 'SHIPPING' || item.node.orderStatus === 'FINISH') &&
																<>
																	{dayjs(item.node.visitAt, 'YYYY.MM.DD HH:mm').format('A') === 'AM' ? '오전' : '오후'} {dayjs(item.node.visitAt, 'YYYY.MM.DD HH:mm').format('hh:mm ')}
																	<strong>배달완료</strong> 예정
																</>
															}
														</p>
														<ul>
															<li className={step === 1 ? 'ing' : (step > 1 ? 'past' : '')}><span>주문완료</span></li>
															<li className={step === 2 ? 'ing' : (step > 2 ? 'past' : '')}><span>입고준비</span></li>
															<li className={step === 3 ? 'ing' : (step > 3 ? 'past' : '')}><span>배달중</span></li>
															<li className={step === 4 ? 'ing' : (step > 4 ? 'past' : '')}><span>배달완료</span></li>
														</ul>
													</div>
												}
											</li>
										)
									}}
								</Transition>
							)
						})}
						{data.gongOrders.pageInfo.hasNextPage === true &&
							<li>
								<div style={{ display: 'grid', placeItems: 'center' }}>
									<Loading />
								</div>
							</li>
						}
						{data.gongOrders.edges.length < 1 &&
							<li className="noData">
								<p>주문내역이 없습니다.</p>
							</li>
						}
					</BottomScrollListener>
				</ul>
			</div>
		</>
	)
}

function ScreenDim() {
	useEffect(() => {
		const body = document.querySelector('body')
		const oldOverflow = body.style.overflow
		console.log(oldOverflow)

		body.style.overflow = "hidden"
		return () => {
			body.style.overflow = oldOverflow
		}
	}, [])
	const style1 = {
		opacity: 0.6,
		visibility: 'visible',
		backgroundColor: 'black',
		position: 'fixed',
		inset: '0px',
		zIndex: 90,
		cursor: 'pointer'
	}
	const style2 = {
		touchAction: 'none',
		visibility: 'visible',
		position: 'fixed',
		overflow: 'auto',
		zIndex: 91,
		width: '100%',
		height: '100%',
		top: '0px',
		left: '0px',
		textAlign: 'center'
	}

	return (
		<>
			<div style={style1}></div>
			<div style={style2}>
				<LoadingCenter />
			</div>
		</>
	)
}