import React, { useEffect } from "react";
import Footer from './Footer'
import { useHistory } from 'react-router-dom'
import ShopList from './ShopList'
import 'swiper/css/swiper.css'

function Shop() {
	const history = useHistory()

	useEffect(() => {
		window.scrollTo(0, 0)
	})

	return (
		<div id="wrap">
			<div id="header" className="subHeader1">
				<div className="header">
					<div className="btnList"><a href="#this" className="txtHide" onClick={(e) => { e.preventDefault(); history.goBack() }}>뒤로가기</a></div>
					<h1>시장보기</h1>
				</div>
			</div>
			<div id="container">
				<div id="contents" className="subCnts3">
					<ShopList />
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Shop