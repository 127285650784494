import React, { useCallback, useEffect, useRef, useState } from "react"
import { Switch, Route, Link, useParams, useHistory } from 'react-router-dom'
import CartFloat, { CartAdd } from './CartIcon'
import { LoadingCenter } from './Loading'
import { loader } from 'graphql.macro'
import { useQuery, useMutation } from '@apollo/client'
import useShopListParam from '../hook/useShopListParam'
import { gqlErrorHandler, chkImageUrl, openMarket, openGoods } from './Library'
import dayjs from 'dayjs'
import _ from 'lodash'
import Modal from './CustomModal'
import ZzimIcon from "./ZzimIcon"

const searchWordGql = loader('../gql/search_word.gql')
const shopListGql = loader('../gql/shop_list.gql')
const getGoodsListGql = loader('../gql/goods_list.gql')
const addWordGql = loader('../gql/add_word.gql')
const formatter = new Intl.NumberFormat()

export default function Search() {
	return (
		<Switch>
			<Route path="/search" exact>
				<SearchBeginning />
			</Route>
			<Route path={[/*'/search/:keyword/:category',*/ '/search/:keyword']}>
				<SearchResult />
			</Route>
		</Switch>
	)
}

function SearchBeginning() {
	const history = useHistory()
	const textInput = useRef()
	const [keyword, setKeyword] = useState({ finished: '', temporary: '' })
	const unmounted = useRef(false)

	useEffect(() => { return () => unmounted.current = true }, [])

	const debounceKeyword = useCallback(_.debounce(function () {
		if (unmounted.current === true) return /* '...memory leak...'에러 방지용, debounce가 SearchBeginning이 언마운트되었을 때 set state하면 발생함.*/
		setKeyword(prev => {
			return { ...prev, finished: prev.temporary }
		})
	}, 500), [])

	const handleKeywordChange = (e) => {
		const value = e.target.value.replace(/%/, '')
		setKeyword(prev => { return { ...prev, temporary: value } })
		debounceKeyword()
	}

	const handleClearInput = (e) => {
		e.preventDefault()
		setKeyword({ finished: '', temporary: '' })
	}

	const handleSearch = (e) => {
		e.preventDefault()
		if (keyword.temporary === '') {
			textInput.current.blur()
			Modal.alert({ content: '상품명을 입력해주세요.' });
			return
		}

		/* 최근검색어 저장 */
		const oldHistory = JSON.parse(localStorage.getItem('s_history')) || []
		const filtered = oldHistory.filter(h => h !== keyword.temporary)
		const newHistory = [keyword.temporary, ...filtered].slice(0, 10) //10개만
		localStorage.setItem('s_history', JSON.stringify(newHistory));

		history.push(`/search/${keyword.temporary}`)
	}

	return (
		<div id="wrap">
			<div id="header" className="subHeader2">
				<div className="header">
					<div className="btnList"><a href="#this" className="txtHide" onClick={(e) => { e.preventDefault(); history.goBack() }}>뒤로가기</a></div>
				</div>
			</div>

			<div id="container">
				<div id="contents">
					<div className="combineSchGp">
						<div className="sticky">
							<h3>무엇을 찾고 계신가요?</h3>
							<div className="schBox">
								<form action="" onSubmit={handleSearch}>
									<input type="text" ref={textInput} value={keyword.temporary} onChange={handleKeywordChange} className="clearInput" placeholder="검색어를 입력해 주세요." />
									{keyword.temporary && <span className="btnTxtDel" onClick={handleClearInput} style={{ display: 'inline' }}></span>}
								</form>
								<a href="#this" className="txtHide btnSch" onClick={handleSearch}>검색</a>
							</div>
							{keyword.finished === keyword.temporary && <RelatedWord keyword={keyword.finished} />} {/* 입력을 멈췄을 때만 렌더링되게 */}
						</div>
						<div className="bgModal60"></div>
						<div className="gpBox">
							<SearchHistory />
							<PopularKeyword />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

function SearchHistory() {
	const [history, setHistory] = useState(JSON.parse(localStorage.getItem('s_history')) || [])

	const handleDelete = (e, item) => {
		e.preventDefault()
		if (!item) {//전체삭제
			localStorage.setItem('s_history', JSON.stringify([]))
			setHistory([])
		} else {
			const restHistory = history.filter(h => h !== item)
			localStorage.setItem('s_history', JSON.stringify(restHistory))
			setHistory(restHistory)
		}
	}

	return history.length > 0 &&
		<div className="recentWord">
			<h4>최근 검색어<a href="#this" className="btnAllDel" onClick={handleDelete}>전체삭제</a></h4>
			<div className="word">
				<ul>
					{history.map((item, k) => (
						<li key={k}>
							<Link to={`/search/${item}`}>{item}</Link>
							<button className="txtHide btnDel" onClick={e => handleDelete(e, item)}>삭제</button>
						</li>
					))}
				</ul>
			</div>
		</div>
}

function PopularKeyword() {
	const values = { gid: process.env.REACT_APP_SERVICE_GID, }
	const { loading, error, data } = useQuery(searchWordGql, { variables: values, fetchPolicy: 'cache-and-network' });

	if (loading) return null
	if (error) { gqlErrorHandler(error); return null; }

	const hour = Math.floor(dayjs().hour() / 2) * 2

	return data.gongSearchWord.length > 0 &&
		<>
			<h4>
				인기 검색어
				<span className="date">{dayjs().hour(hour).format('YYYY.MM.DD HH시 기준')}</span>
			</h4>
			<div className="schWord">
				<ul>
					{data.gongSearchWord.map((item, idx) =>
						<li key={idx}>
							<Link to={`/search/${item.word}`}>
								<strong>{idx + 1}</strong>
								<span>{item.word}</span>
							</Link>
						</li>
					)}
				</ul>
			</div>
		</>
}

function RelatedWord(props) {
	const word = props.keyword
	const values = { gid: process.env.REACT_APP_SERVICE_GID, word }
	const { error, data } = useQuery(searchWordGql, { variables: values, fetchPolicy: 'cache-and-network', skip: !values.word });
	if (!data) return null
	if (error) { gqlErrorHandler(error); return null; }

	return data?.gongSearchWord.length > 0 &&
		<div className="layerSchWord">
			<ul>
				{data?.gongSearchWord.map((item, idx) => {
					const wordArr = item.word.split(word)
					return (
						<li key={`${item.word}${idx}`}>
							<Link to={`/search/${item.word}`}>
								{wordArr.map((w, i) => (i + 1 !== wordArr.length)
									? <React.Fragment key={`${w}${i}`}>{w}<em>{word}</em></React.Fragment>
									: <React.Fragment key={`${w}${i}`}>{w}</React.Fragment >
								)}
							</Link>
						</li>
					)
				})}
			</ul>
		</div>
}

function SearchResult() {
	const history = useHistory()
	const params = useParams()
	const keyword = params.keyword

	const [addWord] = useMutation(addWordGql, { variables: { gid: process.env.REACT_APP_SERVICE_GID, word: keyword } })
	useEffect(() => { addWord() }, [addWord])

	const values = useShopListParam()
	const { error, data } = useQuery(shopListGql, { variables: values })
	const shopIds = data?.gongShops.edges.map(item => item.node.shop.pk) || []

	const { error: goodsError, data: goodsData } = useQuery(getGoodsListGql, { variables: { name: keyword, shopIds: shopIds.join() }, fetchPolicy: 'cache-and-network', skip: shopIds.length < 1 || !keyword })

	if (error || goodsError) { gqlErrorHandler(error || goodsError); return null }
	// if (!goodsData) return null //cache-and-network일때는 loading보다 data로 판단.

	let shopList = new Map()
	goodsData && goodsData.goodsList.edges.forEach(element => {
		const key = element.node.shopinfo
		!shopList.has(key) && shopList.set(key, [])
		shopList.set(key, [...shopList.get(key), element.node])
	})

	return (
		<div id="wrap">
			<div id="header" className="subHeader2">
				<div className="header">
					<div className="btnList"><a href="#this" className="txtHide" onClick={(e) => { e.preventDefault(); history.goBack() }}>뒤로가기</a></div>
					<div className="hSchBox" onClick={e => { e.preventDefault(); history.goBack() }}>
						<input type="text" defaultValue={keyword} className="clearInput" placeholder="검색어를 입력해 주세요." readOnly />
						<a href="#this" className="txtHide btnSch">검색</a>
					</div>
				</div>
			</div>
			<div id="container">
				<div id="contents">
					<div className="bookmarkList">
						{!goodsData && <LoadingCenter />}
						{goodsData?.goodsList.edges.length < 1 &&
							<div className="lists">
								<div className="noData">
									<p>
										<em>{keyword}</em>의 검색결과가 없습니다.
									</p>
								</div>
							</div>
						}
						{(() => {
							let list = []
							shopList.forEach((menu, shop) =>
								list.push(
									<div className="lists" key={shop.pk}>
										<h3><a href="#this" onClick={(e) => openMarket(shop.pk, e)}>{shop.name}</a></h3>
										<ul>
											{menu.map((item) => {
												let dimClassName = ''
												dimClassName = !item.isOpen ? 'prepare' : dimClassName
												dimClassName = item.soldout ? 'soldOut' : dimClassName
												const allowClick = dimClassName === ''

												return (
													<li key={item.pk} className={dimClassName}>
														<a href="#this" onClick={(e) => { e.preventDefault(); allowClick && openGoods(shop.pk, item.pk, e) }}>
															<div className="img">
																{chkImageUrl(item.imageMain) && <img src={chkImageUrl(item.imageMain)} alt="" />}
															</div>
														</a>
														<div className="cnt">
															<div className="likeBasket">
																<ZzimIcon goods={item} />
																<CartAdd goods={item} />{/* dim 시 클릭안되는건 css에서 처리한거임... */}
															</div>
															<a href="#this" onClick={(e) => { e.preventDefault(); allowClick && openGoods(shop.pk, item.pk, e) }}>
																<dl>
																	<dt>{item.name}</dt>
																	<dd>{item.shopinfo?.name} [{item.subShop?.name}]</dd>
																</dl>
																<p>{formatter.format(item.price)}원</p>
															</a>
														</div>
													</li>
												)
											})}
										</ul>
									</div>
								))
							return list
						})()}
					</div>
					<CartFloat />
				</div>
			</div>
		</div>
	)
}