
import useAddrInfo from '../hook/useAddrInfo'
import { useParams } from 'react-router-dom'

export default function (param) {
	param = param || {}
	let values = {}

	const { category: cateCode } = useParams()
	values.categories = cateCode || null

	// if ('asian' === values.categories) {
	// 	delete values.categories
	// 	values.region = 56
	// }
	// values.name = param.keyword || null
	// values.searchComplete = (param.searchComplete && param.keyword) ? true : false
	values.gid = process.env.REACT_APP_SERVICE_GID
	// values.first = 10
	values.km = 20

	const { locAddressMain } = useAddrInfo()
	values.geo = param.reCoords || `${locAddressMain.lng},${locAddressMain.lat}`

	var addrCode = `${locAddressMain.hCode},${locAddressMain.bCode}`

	if (param.filter) {
		/* 필터있는 경우 필터설정을 따르도록 */
		let getMethod = []
		for (const [k, v] of Object.entries(param.filter.getMethod)) v && getMethod.push(k)
		let payMethod = []
		for (const [k, v] of Object.entries(param.filter.payMethod)) v && payMethod.push(k)

		const deliveryFee = param.filter.deliveryFee.replace(/^_(.*)$/, '$1') // 맨앞_제거
		const minAmount = param.filter.minAmount.replace(/^_(.*)$/, '$1') // 맨앞_제거

		values = {
			...values,
			sort: param.filter.sorty,
			minPrice: minAmount !== 'all' ? minAmount : null,
			orderType: getMethod.join(),
			payType: payMethod.join(),
			minShipPrice: deliveryFee !== 'all' ? deliveryFee : null,
			addrCode: getMethod.includes('delivery') ? addrCode : null, /* 필터에 배달이 있을 때만 addrCode를 적용해란다. */
		}
	} else {
		/* 필터없는 경우는 현재 없는것 같긴함... */
		values = {
			...values,
			sort: 'near',
			minPrice: null,
			orderType: "reservation,takeout,delivery",
			payType: "", /* orderType와 payType두개 모두 ""로 하면 gql에러남... 값많은 이것만 ""로하자.*/
			minShipPrice: null,
			addrCode: addrCode,
		}
	}

	return values;
}