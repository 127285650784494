import React from "react";
import { useQuery, useMutation } from '@apollo/client'
import Modal from './CustomModal'
import { Link } from 'react-router-dom'
import { gql, loader } from 'graphql.macro'
import { gqlErrorHandler, isLogin } from './Library'

const gid = process.env.REACT_APP_SERVICE_GID
const cartAddGql = loader('../gql/cart_add.gql')
/* orderType: ""은 똑같은 콘솔창에 variables리스트인 /cart와 캐시키충돌경고나서 추가했음. */
const cartCountGql = gql`
	query getCartCount($gid: Float!, $status: String = "CART") { 
		gongOrders(gid: $gid, status: $status, orderType: "") {
			totalCount
		}
	}
`
export default function CartFloat() {
    const { data } = useQuery(cartCountGql, { variables: { gid }, fetchPolicy: 'cache-and-network', skip: !isLogin() })
    const on = data?.gongOrders.totalCount > 0

    return <Link to='/cart' className={`txtHide btnFtBasket ${on ? 'on' : ''}`}>장바구니{on && <span>N</span>}</Link>
}

export function CartAdd(props) {
    const [cartAdd] = useMutation(cartAddGql, {
        variables: {
            shopId: props.goods.shopinfo.pk,
            goodsId: props.goods.pk,
            count: 1
        },
        refetchQueries: [
            {
                query: cartCountGql,
                variables: { gid }
            }
        ]
    })
    const handleCartAdd = async (e) => {
        e.preventDefault()

        if (!isLogin()) {
            Modal.alert({ content: '로그인이 필요합니다.' });
            return
        }

        try {
            const res = await cartAdd()
            if (res.data.orderCartAdd.result === false) {
                Modal.alert({ content: res.data.orderCartAdd.msg })
                return
            }
            Modal.alert({ content: '상품이 장바구니에 담겼습니다.' })
        } catch (e) {
            gqlErrorHandler(e)
        }
    }
    return <a href="#this" onClick={handleCartAdd} className="txtHide btnBasket">장바구니</a>
}