import { useReactiveVar } from '@apollo/client';
import { locAddressMainVar } from '../components/ApolloClient'

export default function () { // 간단한 처리만 추가, 부하될만한것 넣지 말도록.
	const locAddressMain = useReactiveVar(locAddressMainVar)
	const geo = locAddressMain ? `${locAddressMain.lng},${locAddressMain.lat}` : '129.330112,35.543721'
	const addrCode = locAddressMain ? `${locAddressMain.hCode},${locAddressMain.bCode}` : '2602056,3114010500'

	let headerAddrTxt = null
	if (locAddressMain) {
		if (locAddressMain.sido && locAddressMain.gugun && locAddressMain.dong)
			headerAddrTxt = `${locAddressMain.sido} ${locAddressMain.gugun} ${locAddressMain.dong}`
		else
			headerAddrTxt = locAddressMain.oldAddr1
	} else {
		headerAddrTxt = '울산광역시 남구 돋질로 233(달동)'
	}

	return { locAddressMain, geo, addrCode, headerAddrTxt }
}