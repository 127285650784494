import React, { useState, useEffect } from "react";

function Timer({ startTime, min, sec, onTimeout }) {
	const [timer, setTimer] = useState({ minnutes: 5, seconds: 0 })
	useEffect(() => {
		let initMin = parseInt(min) || min === 0 ? parseInt(min) : 5;
		let initSec = parseInt(sec) || sec === 0 ? parseInt(sec) : 0;
		setTimer({ minnutes: initMin, seconds: initSec });
	}, [startTime, min, sec]);
	useEffect(() => {
		const countdown = setInterval(() => {
			if (timer.seconds > 0) {
				setTimer({
					minnutes: timer.minnutes,
					seconds: (parseInt(timer.seconds) - 1)
				});
			} else if (timer.seconds === 0 && timer.minnutes > 0) {
				setTimer({
					minnutes: (parseInt(timer.minnutes) - 1),
					seconds: 59
				});
			} else {
				clearInterval(countdown);
			}
		}, 1000)
		return () => clearInterval(countdown);
	}, [timer]);
	const timeoutCallback = () => {
		if (timer.minnutes === 0 && timer.seconds === 0) {
			onTimeout();
		}
	}
	useEffect(timeoutCallback, [timer]);
	return (
		<div className="time">
			{timer.minnutes < 10 ? `0${timer.minnutes}` : timer.minnutes}:{timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
		</div>
	)
}

export default Timer