import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import BottomScrollListener from 'react-bottom-scroll-listener';
import { loader } from 'graphql.macro';
import { gqlErrorHandler } from './Library';
import { Query } from '@apollo/client/react/components';
import { withApolloClient } from './ApolloClient';

const PUSH_QUERY = loader('../gql/push_list.gql');
const RECENT_PUSH_QUERY = loader('../gql/recent_push.gql');
const categoryClass = {
	EVENT: { class_name: 'type1', link_url: '/mypage/notice' },
	NOTICE: { class_name: 'type6', link_url: '/mypage/notice' },
	UPDATE: { class_name: 'type5', link_url: '/mypage/notice' },
	WORKING: { class_name: 'type4', link_url: '/order' },
	PAY: { class_name: 'type9', link_url: '/order' },
	FINISH: { class_name: 'type9', link_url: '/order' },
	SHIP_FINISH: { class_name: 'type9', link_url: '/order' },
	CANCEL: { class_name: 'type10', link_url: '/order' },
	QNA: { class_name: 'type3', link_url: '/mypage/inquiry' },
};


class MessageList extends React.Component {
	constructor(props) {
		super(props);
		this.setRecentPush = this.setRecentPush.bind(this);
		this.handleFetchMore = this.handleFetchMore.bind(this);
		this.doFetchMore = React.createRef(true);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.setRecentPush();
	}

	setRecentPush = () => {
		this.props.client.query({
			query: RECENT_PUSH_QUERY,
			variables: { gongId: process.env.REACT_APP_SERVICE_GID }
		}).then((result) => {
			if (result.data.gongPushes.edges.length === 1) {
				let push = {
					id: result.data.gongPushes.edges[0].node.id,
					createdAt: result.data.gongPushes.edges[0].node.createdAt
				}
				localStorage.setItem('recentPush', JSON.stringify(push))
			}
		}).catch((err) => { gqlErrorHandler(err) });
	}

	handleFetchMore = (fm, data) => {
		if (data.gongPushes.pageInfo.hasNextPage === false) return
		if (this.doFetchMore === false) return
		this.doFetchMore.current = false;

		fm({
			variables: {
				gongId: process.env.REACT_APP_SERVICE_GID,
				cursor: data.gongPushes.pageInfo.endCursor
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongPushes.edges;
				const pageInfo = fetchMoreResult.gongPushes.pageInfo;
				return newEdges.length
					? {
						gongPushes: {
							__typename: previousResult.gongPushes.__typename,
							edges: [...previousResult.gongPushes.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		}).then(() => this.doFetchMore.current = true);
	}

	render() {
		return (
			<div id="wrap">
				<div id="header" className="subHeader1">
					<div className="header">
						<div className="btnList">
							<a href="#this" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} className="txtHide btnHback">뒤로가기</a>
						</div>
						<h1>알림내역</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts4">
						<div className="noticeAlarm">
							<div className="topNoti">
								<p>최근 3달 내의 알림만 확인하실 수 있습니다.</p>
							</div>
							<Query query={PUSH_QUERY} variables={{ gongId: process.env.REACT_APP_SERVICE_GID }}>
								{({ loading, error, data, fetchMore }) => {
									if (loading) return null
									if (error) { gqlErrorHandler(error); return null }
									const entries = data.gongPushes.edges || [];
									if (entries.length === 0) { return <div style={{ height: 'calc(100vh - 106px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p className="no-data">알림내역이 없습니다.</p></div> }
									return (
										<ul>
											<BottomScrollListener onBottom={() => this.handleFetchMore(fetchMore, data)}>
												{
													entries.map((v, k) => (
														<Message key={k} message={v.node} />
													))
												}
											</BottomScrollListener>
										</ul>
									)
								}}
							</Query>
						</div>
					</div>
				</div>
			</div >
		)

	}
}

class Message extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			message: this.props.message
		}
		this.messageTimeHandler = this.messageTimeHandler.bind(this);
	}

	messageTimeHandler = (createdAt) => {
		let msg = "";
		let createdAt_format = createdAt.replace(/-/g, '/');
		let create_timestamp = new Date(createdAt_format).getTime();
		let now_timestamp = new Date().getTime();
		let day_ago = (60 * 60 * 24);
		let week_ago = (60 * 60 * 24 * 7);
		let eight_day_ago = (60 * 60 * 24 * 8);
		let time_diff = parseInt(now_timestamp / 1000) - parseInt(create_timestamp / 1000);

		if (time_diff < day_ago) {
			if (time_diff < 3600) {
				msg = parseInt(time_diff / 60) + "분전";
			} else {
				msg = parseInt(time_diff / 3600) + "시간전";
			}
		} else if (time_diff < week_ago) {
			msg = parseInt(time_diff / day_ago) + "일전";
		} else if (time_diff < eight_day_ago) {
			msg = '일주일전';
		} else {
			msg = createdAt;
		}
		return msg;
	}
	render() {
		const { message } = this.state
		const category_info = categoryClass[message.category];
		return (
			<li>
				<NavLink to={category_info && category_info.link_url ? category_info.link_url : '#this'} className="alTit">
					{message.category && <h4 className={category_info && category_info.class_name ? 'typeSym ' + category_info.class_name : 'typeSym type3'}>{message.categoryName}</h4>}
					<p>
						{message.title}
					</p>
					{/* {(message.category === 'FINISH' || message.category === 'SHIP_FINISH') && <p><span className="txtSub1">*리뷰쓰기는 주문이후 3일동안만 가능합니다.</span></p>} */}
					<span>{this.messageTimeHandler(message.createdAt)}</span>
				</NavLink>
			</li>
		)
	}

}

export default withApolloClient(withRouter(MessageList))