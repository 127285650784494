import React from "react";
import { withRouter } from "react-router-dom";
import Modal from './CustomModal';
import Popup from './Popup';

export default withRouter(class AgreeTerms extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			chk_all: false,
			chk_1: false,
			chk_2: false,
			chk_3: false,
			chk_4: false,
			chk_5: false
		}
		this.handleCheck = this.handleCheck.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleBack = this.props.onBack.bind(this);
	}

	handleCheck(e) {
		const value = e.target.checked;
		const name = e.target.name;
		let preState = this.state;

		if (name === 'chk_all') {
			Object.keys(preState).map((key) => preState[key] = value);
		} else {
			preState[name] = value;

			preState.chk_all = true;
			Object.keys(preState).map((key) => preState.chk_all = preState.chk_all && preState[key]);
		}

		this.setState(preState);
	}

	handleSubmit(e) {
		e.preventDefault();
		const permAddr = localStorage.getItem("permAddr");
		if (this.state.chk_1 && this.state.chk_2 && this.state.chk_3 && (this.state.chk_4 || permAddr === 'Y') && this.state.chk_5) {
			this.props.onAgree(this.state.chk_5);
		} else {
			Modal.warning({ content: '필수 약관에 동의해 주세요' });
		}
	}

	render() {
		const permAddr = localStorage.getItem("permAddr");
		return (
			<div id="wrap">
				<div id="header" className="subHeader">
					<div className="header">
						<div className="btnList">
							<a href="#this" className="txtHide" onClick={this.handleBack}>뒤로가기</a>
						</div>
						<h1>약관동의</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts">
						<div className="agreeJoin">
							<div className="allAgree">
								<label className="chkBox1">
									<input type="checkbox" name="chk_all" checked={this.state.chk_all} onChange={this.handleCheck} style={{ display: 'none !important' }} />
									<span className="icon"></span>
									<span className="text">아래 약관에 모두 동의합니다.</span>
								</label>
							</div>
							<ul>
								<li>
									<label className="chkBox1">
										<input type="checkbox" name="chk_1" checked={this.state.chk_1} onChange={this.handleCheck} style={{ display: 'none !important' }} />
										<span className="icon"></span>
										<span className="arrSpan">
											<span className="text">(필수) 통합회원 이용약관</span>
											<Popup href="https://smallbiz.notion.site/_-b619bc9024644610b3e49430737e0f93">
												<img src="/img/sub/bg_ico_arr1.png" className="arrImg" onError={e => e.target.style.display = 'none'} alt="통합회원 이용약관" />
											</Popup>
										</span>
									</label>
								</li>
								<li>
									<label className="chkBox1">
										<input type="checkbox" name="chk_2" checked={this.state.chk_2} onChange={this.handleCheck} style={{ display: 'none !important' }} />
										<span className="icon"></span>
										<span className="arrSpan">
											<span className="text">(필수) 서비스 이용약관</span>
											<Popup href="https://smallbiz.notion.site/_-e3e51ed410f2462eb7ef4775a2e19cbb">
												<img src="/img/sub/bg_ico_arr1.png" className="arrImg" onError={e => e.target.style.display = 'none'} alt="서비스 이용약관" />
											</Popup>
										</span>
									</label>
								</li>
								<li>
									<label className="chkBox1">
										<input type="checkbox" name="chk_3" checked={this.state.chk_3} onChange={this.handleCheck} style={{ display: 'none !important' }} />
										<span className="icon"></span>
										<span className="arrSpan">
											<span className="text">(필수) 개인정보 수집 및 이용약관</span>
											<Popup href="https://smallbiz.notion.site/_-8dbe4046aed0497cbeeb075a31edab5e">
												<img src="/img/sub/bg_ico_arr1.png" className="arrImg" onError={e => e.target.style.display = 'none'} alt="개인정보 수집 및 이용약관" />
											</Popup>
										</span>
									</label>
								</li>
								{permAddr !== 'Y' &&
									<li>
										<label className="chkBox1">
											<input type="checkbox" name="chk_4" checked={this.state.chk_4} onChange={this.handleCheck} style={{ display: 'none !important' }} />
											<span className="icon"></span>
											<span className="arrSpan">
												<span className="text">(필수) 위치기반 서비스 이용약관</span>
												<Popup href="https://smallbiz.notion.site/_-047690bec15942eabfe65eb55b639211">
													<img src="/img/sub/bg_ico_arr1.png" className="arrImg" onError={e => e.target.style.display = 'none'} alt="위치기반 서비스 이용약관" />
												</Popup>
											</span>
										</label>
									</li>
								}
								<li>
									<label className="chkBox1">
										<input type="checkbox" name="chk_5" checked={this.state.chk_5} onChange={this.handleCheck} style={{ display: 'none !important' }} />
										<span className="icon"></span>
										<span className="text">(필수) 만 14세 이상입니다.</span>
									</label>
								</li>
							</ul>
							<div className="btnCenter">
								<a href="#this" onClick={this.handleSubmit} className={(this.state.chk_1 && this.state.chk_2 && this.state.chk_3 && (this.state.chk_4 || permAddr === 'Y') && this.state.chk_5 ? "btnTy2 on" : "btnTy2")}>확인</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
});