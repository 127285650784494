import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client'
import { loader } from "graphql.macro";
import { gqlErrorHandler, chkImageUrl, openGoods } from "./Library";
import Modal from './CustomModal';
import ZzimIcon from './ZzimIcon';
import CartFloat, { CartAdd } from './CartIcon';
import { LoadingCenter } from './Loading'

const formatter = new Intl.NumberFormat();
const ZZIM_LIST_QUERY = loader("../gql/member_zzim_list.gql");
const ZZIM_DELETE_ALL_MUTATION = loader("../gql/setMemberZzimRemoveAll.gql");

export default function () {
	const [mayorList, setMayorList] = useState([]);
	const [zzimList, setZzimList] = useState([]);
	const [isLoding, setIsLoading] = useState(true);
	const [zzimDeleteAll] = useMutation(ZZIM_DELETE_ALL_MUTATION);

	const { loading, error, data, refetch } = useQuery(ZZIM_LIST_QUERY, { variables: { gid: process.env.REACT_APP_SERVICE_GID }, fetchPolicy: 'network-only' });
	useEffect(() => {
		const onCompleted = (data) => {
			if (data.memberZzimList.edges.length === 0) {
				setMayorList([]);
				setZzimList([]);
				setIsLoading(false);
			} else {
				const rows = data?.memberZzimList.edges;
				let mayor_data = [];
				let zzim_data = [];
				rows.forEach(function (v) {
					mayor_data.push(v.node.goods.shopinfo);
					zzim_data[v.node.goods.shopinfo.pk] = [];
				});
				const uniqueByMayor = [...new Map(mayor_data.map(item => [item['pk'], item])).values()];
				rows.forEach(function (v) {
					zzim_data[v.node.goods.shopinfo.pk].push(v.node);
				});
				setMayorList(uniqueByMayor);
				setZzimList(zzim_data);
				setIsLoading(false);
			}
		};
		const onError = (error) => {
			gqlErrorHandler(error);
			return null;
		};
		if (onCompleted) {
			if (onCompleted && !loading && !error) {
				onCompleted(data);
			} else if (onError && !loading && error) {
				onError(error);
			}
		}
	}, [loading, error, data]);

	const deleteAllZzimList = (e) => {
		e.preventDefault();
		if (zzimList.length === 0) {
			Modal.warning({
				content: '찜 한 상품이 없습니다.',
			});
		} else {
			Modal.confirm({
				centered: true,
				title: "찜한 상품을 전체 삭제 하시겠습니까?",
				onOk: async () => {
					try {
						await zzimDeleteAll({ variables: { mode: 'remove' } })
						refetch()
					} catch (e) { gqlErrorHandler(e) }
				}
			});
		}
	}
	return (
		<div id="wrap">
			<div id="header" className="subHeader1">
				<div className="header">
					<div className="btnList">
						<NavLink to="/mypage" className="txtHide">뒤로가기 </NavLink>
					</div>
					<h1>찜</h1>
					<a href="#this" className="btnAllDel" onClick={deleteAllZzimList}>전체삭제</a>
				</div>
			</div>
			<div id="container">
				<div id="contents">
					{isLoding ? <LoadingCenter /> :
						mayorList.length === 0 ?
							<div className="bookmarkList">
								<div className="lists">
									<div className="noData">
										<p>
											찜 한 상품이 없습니다.
											<span>
												마음에 드는 상품에<br />
												하트를 눌러 주세요.
											</span>
										</p>
									</div>
								</div>
							</div>
							:
							<>
								<div className="bookmarkList">
									{
										mayorList && mayorList.map((mayor) => (
											<div className="lists" key={mayor.pk}>
												<h3>{mayor.name}</h3>
												<ul>
													{zzimList[mayor.pk] && zzimList[mayor.pk].map((val) => (
														<BookmarkShop key={val.pk} {...val} unsetZzim={() => refetch()} />
													))}
												</ul>
											</div>

										))
									}
								</div>
								<CartFloat />
							</>
					}
				</div>
			</div>
		</div>
	);
}


const BookmarkShop = (props) => {
	const goods = props.goods;
	return (
		<li className={!goods.isOpen || goods.soldout ? "noting" : ""}>
			<div className="img">
				<img src={chkImageUrl(goods.imageMain)} alt="" onClick={goods.isOpen && !goods.soldout ? (e) => { openGoods(goods.shopinfo.pk, goods.pk, e) } : () => { return false; }} />
			</div>
			<div className="cnt">
				<div className="likeBasket">
					<ZzimIcon goods={goods} forceMine={true} handleUnset={props.unsetZzim} />
					<CartAdd goods={goods} />
					<div id="toast"></div>
				</div>
				<dl>
					<dt>{goods.name}</dt>
					<dd>{goods.subShop.name}</dd>
				</dl>
				<p>{formatter.format(goods.price)} 원</p>
			</div>
		</li >
	)
}