import React from "react";
import { withRouter } from "react-router-dom"
import Popup from './Popup';

class PermissionAddr extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chk_addr: false,
		}
		this.handleAgrre = this.handleAgrre.bind(this);
	}
	handleAgrre(e) {
		e.preventDefault();
		if (this.state.chk_addr) {
			localStorage.setItem('permAddr', 'Y');
			this.props.history.replace('/mypage/set-address');
		}
		return false;
	}
	render() {
		return (
			<div id="wrap">
				<div id="container">
					<div id="contents" className="subCnts">
						<div className="termsAgree">
							<h3>이용약관 동의</h3>
							<span>원활한 서비스 제공을 위해 이용 약관 동의 내용에<br />동의해주세요.</span>

							<div className="agreeJoin">
								<div className="allAgree">
									<label className="chkBox1">
										<input type="checkbox" checked={this.state.chk_addr} onChange={() => this.setState(prevState => ({ chk_addr: !prevState.chk_addr }))} />
										<span className="icon"></span>
										<span className="text">아래 약관에 모두 동의합니다.</span>
									</label>
								</div>
								<ul>
									<li>
										<label className="chkBox1">
											<input type="checkbox" name="chk_1" checked={this.state.chk_addr} onChange={() => this.setState(prevState => ({ chk_addr: !prevState.chk_addr }))} style={{ display: 'none !important' }} />
											<span className="icon"></span>
											<span className="text">(필수) 위치기반 서비스 이용약관</span>
											<Popup href="https://smallbiz.notion.site/_-047690bec15942eabfe65eb55b639211" target="_blank" rel="noopener noreferrer" className="linkArr"></Popup>
										</label>
									</li>
								</ul>
								<div className="btnFixed">
									<a href="#this" onClick={this.handleAgrre} className={this.state.chk_addr ? "btnTy2 on" : "btnTy2"}>확인</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	}
}

export default withRouter(PermissionAddr)