import React from "react";
import axios from "axios";
import { withRouter, NavLink } from "react-router-dom";
import Modal from './CustomModal';
import { withApolloClient } from './ApolloClient';
import InputDelButton from "./InputDelButton";
import $ from "jquery";
import Timer from './Timer';

const modes = ['password', 'mobile'];

class SetInfo extends React.Component {
	constructor(props) {
		super(props);

		let nowMod = Array(modes.length);
		modes.map((val, idx) => nowMod[idx] = false);
		this.state = {
			nowMod: nowMod,
			values: {
				passwordNow: '',
				password: '',
				passwordCheck: '',
				mobile: '',
				sendedMobile: '',
				confirmNo: ''
			},
			memberInfo: {
				email: '',
				mobile: '',
				pw_changed_at: ''
			},
			msgTimeout: false,
			msgSendDate: null,
			validate: {
				password: false,
				passwordCheck: false
			},
			validateContent: {
				password: '',
				passwordCheck: ''
			}
		};

		axios.get(`${process.env.REACT_APP_SERVER_REST}/members/info`,
			{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
			.then(res => {
				if (res.data.result) {
					const memberInfo = {
						email: res.data.email,
						mobile: res.data.phone,
						pw_changed_at: res.data.pw_changed_at
					};

					let defaultValues = this.state.values;
					this.setState({
						memberInfo: memberInfo,
						values: defaultValues
					});
				}
			});

		this.handleModify = this.handleModify.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.handleSubmitMobile = this.handleSubmitMobile.bind(this);
		this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
		this.handleDestroy = this.handleDestroy.bind(this);
		this.handleTimeout = this.handleTimeout.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handleTimeout() {
		this.setState({ msgTimeout: true });
		return false;
	}

	handleModify(id, e) {
		e.preventDefault();
		let nowMod = this.state.nowMod;
		modes.map((val, idx) => nowMod[idx] = val === id);
		this.setState({ nowMod: nowMod });
	}

	handleClear(e) {
		if (e) e.preventDefault();
		let nowMod = this.state.nowMod;
		modes.map((val, idx) => nowMod[idx] = false);
		this.setState({ nowMod: nowMod });
	}

	handleInput(mode, value) {
		let values = this.state.values;
		Object.keys(values).map((key) => key === mode ? values[key] = value : null);

		let validate = this.state.validate;
		let validateContent = this.state.validateContent;

		// 비밀번호 입력값 검증
		switch (mode) {
			case 'passwordNow':
				break;
			case 'password':
				const regPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,30}$/;

				if (value) {
					if (regPassword.test(value)) {
						validate.password = true;
						validateContent.password = '사용 가능한 비밀번호입니다.';
					} else {
						validate.password = false;
						validateContent.password = '영문+숫자 8자리 이상 입력해주세요.';
					}
					//비밀번호 체크
					if (value === values.passwordCheck) {
						validate.passwordCheck = true;
						validateContent.passwordCheck = '비밀번호가 일치합니다.';
					} else {
						validate.passwordCheck = false;
						validateContent.passwordCheck = '비밀번호가 일치하지 않습니다.';
					}
				} else {
					validate.password = false;
					validateContent.password = null;

					if (values.passwordCheck) {
						validate.passwordCheck = false;
						validateContent.passwordCheck = '비밀번호가 일치하지 않습니다.';
					}
				}
				break;
			case 'passwordCheck':
				if (value) {
					if (value === values.password) {
						validate.passwordCheck = true;
						validateContent.passwordCheck = '비밀번호가 일치합니다.';
					} else {
						validate.passwordCheck = false;
						validateContent.passwordCheck = '비밀번호가 일치하지 않습니다.';
					}
				} else {
					validate.passwordCheck = false;
					validateContent.passwordCheck = null;
				}
				break;
			case 'confirmNo':
				break;
			case 'mobile':
				break;
			default:
				return;
		}

		this.setState({ values: values, validate: validate, validateContent: validateContent });
	}

	handleSend(e) {
		e.preventDefault();
		let values = this.state.values;
		if (!values.mobile) return;
		axios.post(`${process.env.REACT_APP_SERVER_REST}/members/phone/edit`,
			{ phone: values.mobile },
			{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
			.then(res => {
				if (res.data.result) {
					Modal.success({ content: '인증번호를 전송했습니다.' });
					// 인증번호가 전송되었을 경우 전송된 휴대폰번호를 저장
					values.sendedMobile = values.mobile;
					this.setState({ values: values, msgTimeout: false, msgSendDate: new Date().getTime() });
				} else {
					Modal.warning({ content: res.data.msg });
				}
			})
			.catch(res => { Modal.error({ content: '인증번호 전송중 오류가 발생하였습니다.' }) });
	}

	handleSubmitMobile(e) {
		e.preventDefault();
		let values = this.state.values;
		if (values.sendedMobile && values.confirmNo) {
			axios.post(`${process.env.REACT_APP_SERVER_REST}/members/phone/ok`,
				{ auth: values.confirmNo },
				{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
				.then(res => {
					if (res.data.result) {
						let memberInfo = this.state.memberInfo;
						memberInfo.mobile = values.sendedMobile;
						localStorage.member_mobile = values.sendedMobile;
						values.mobile = '';
						values.sendedMobile = '';
						values.confirmNo = '';
						this.setState({ memberInfo: memberInfo });
						Modal.success({
							content: '휴대폰번호 변경이 완료되었습니다.',
							onOk: this.handleClear()
						});
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => { Modal.error({ content: '인증번호 전송중 오류가 발생하였습니다.' }) });
		}
	}

	handleSubmitPassword(e) {
		e.preventDefault();
		let values = this.state.values;
		if (values.passwordNow && values.password && values.passwordCheck) {
			const regPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,30}$/;
			if (!regPassword.test(values.password)) {
				Modal.warning({ content: '비밀번호는 영문+숫자 8자리이상 입력해 주세요.' });
				$('input[name=password]').focus();
				return;
			}

			if (values.password !== values.passwordCheck) {
				Modal.warning({ content: '비밀번호가 일치하지 않습니다. 다시 입력해 주세요.' });
				$('input[name=passwordCheck]').focus();
				return;
			}

			axios.post(`${process.env.REACT_APP_SERVER_REST}/v2/api/member/password/change`,
				`new_pw=${values.password}&old_pw=${values.passwordNow}`,
				{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
				.then(res => {
					if (res.data.result) {
						values.password = '';
						values.passwordNow = '';
						values.passwordCheck = '';
						this.setState({
							values: values,
							validate: {
								password: false,
								passwordCheck: false
							},
							validateContent: {
								password: '',
								passwordCheck: ''
							}
						});

						axios.get(`${process.env.REACT_APP_SERVER_REST}/members/info`,
							{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
							.then(res => {
								if (res.data.result) {
									const memberInfo = {
										email: res.data.email,
										mobile: res.data.phone,
										pw_changed_at: res.data.pw_changed_at
									};
									this.setState({ memberInfo: memberInfo });
								}
							});

						Modal.success({
							content: '비밀번호 변경이 완료되었습니다.',
							onOk: this.handleClear()
						});
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => { Modal.error({ content: '비밀번호 전송중 오류가 발생하였습니다.' }) });
		}
	}

	handleLogout(e) {
		e.preventDefault();

		Modal.confirm({
			content: '로그아웃 하시겠습니까?',
			onOk: () => {
				let appVersion = '';
				let osVersion = '';
				let locAddressMain = '';
				let locRecentGoods = '';

				if (localStorage.app_version) appVersion = localStorage.app_version;
				if (localStorage.os_version) osVersion = localStorage.os_version;
				if (localStorage.locAddressMain) locAddressMain = localStorage.locAddressMain;
				if (localStorage.locRecentGoods) locRecentGoods = localStorage.locRecentGoods;

				localStorage.clear();
				if (appVersion) localStorage.app_version = appVersion;
				if (osVersion) localStorage.os_version = osVersion;
				if (locAddressMain) localStorage.locAddressMain = locAddressMain;
				if (locRecentGoods) localStorage.locRecentGoods = locRecentGoods;
				//this.props.client.resetStore();

				/* global AppScheme */
				typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://logout`);

				this.props.history.push('/');
			}
		});
	}

	handleDestroy(e) {
		e.preventDefault();

		Modal.confirm({
			content: '회원탈퇴 하시겠습니까?',
			onOk: () => {
				axios.post(`${process.env.REACT_APP_SERVER_REST}/members/destroy`,
					{},
					{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
					.then(res => {
						if (res.data.result) {
							Modal.success({
								content: '회원탈퇴가 완료되었습니다.',
								onOk: () => {
									const locAddressMain = localStorage.locAddressMain ? localStorage.locAddressMain : null
									localStorage.clear();
									if (locAddressMain) localStorage.locAddressMain = locAddressMain

									this.props.client.resetStore();
									this.props.history.push('/');
								}
							});

						} else {
							Modal.warning({
								content: res.data.msg
							});
						}
					});

			}
		});
	}

	render() {
		const mobile = this.state.memberInfo.mobile
			? this.state.memberInfo.mobile.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")
			: '';
		return (
			<>
				<div id="wrap">
					<div id="header" className="subHeader1">
						<div className="header">
							<div className="btnList">
								<NavLink to="/mypage" className="txtHide btnHback">뒤로가기</NavLink>
							</div>
							<h1>정보수정</h1>
						</div>
					</div>
					<div id="container">
						<div id="contents" className="subCnts">
							<div className="memInfoModify">
								<h3>기존 정보</h3>
								<div className="formWrite">
									<div className="box">
										<h4><em>*</em> 이메일</h4>
										<div className="inputSt1 disabled">
											<input type="text" name="" value={this.state.memberInfo.email} className="clearInput" placeholder="아이디(이메일) 입력" disabled="disabled" />
											<div className="inputFocus"></div>
										</div>
									</div>
									<div className="box">
										<h4><em>*</em> 비밀번호</h4>
										<div className="txtInput">
											<p>최종 변경일 : <span>{this.state.memberInfo.pw_changed_at}</span></p>
										</div>
										<a href="#this" className="btnTy5 layerPwChange_open" layer="1" onClick={(e) => this.handleModify('password', e)}>변경</a>
									</div>
									<div className="box">
										<h4><em>*</em> 휴대폰번호</h4>
										<div className="txtInput">
											<p>{mobile}</p>
										</div>
										<a href="#this" className="btnTy5 layerPhoneChange_open" layer="2" onClick={(e) => this.handleModify('mobile', e)}>변경</a>
									</div>
									<div className="logoutEnd">
										<span><a href="#this" onClick={this.handleLogout}>로그아웃</a></span>
										<span><a href="#this" onClick={this.handleDestroy}>회원탈퇴</a></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<PasswordForm nowMod={this.state.nowMod} values={this.state.values} validate={this.state.validate} validateContent={this.state.validateContent} onSubmit={this.handleSubmitPassword} onInput={this.handleInput} onClear={this.handleClear} />
				<MobileForm nowMod={this.state.nowMod} values={this.state.values} onSubmit={this.handleSubmitMobile} onInput={this.handleInput} onClear={this.handleClear} onSend={this.handleSend} onTimeout={this.handleTimeout} msgSendDate={this.state.msgSendDate} msgTimeout={this.state.msgTimeout} />

			</>

		);
	}
}

function PasswordForm(props) {
	const onClass = props.values.passwordNow && props.values.password && props.values.passwordCheck ? ' on' : '';
	return props.nowMod[0] ? (
		<>
			<div className="custom_modal_wrapper">
				<div id="layerPwChange" className="popup_content custom_modal_content" >
					<div className="layerBox">
						<div className="layerHd">
							<h3>비밀번호 변경</h3>
						</div>
						<div className="layerCnt">
							<div className="layerCom">
								<h4>현재 비밀번호</h4>
								<div className="box">
									<div className="inputSt1">
										<InputDelButton type="password" value={props.values.passwordNow} name="passwordNow" className="clearInput" placeholder="현재 비밀번호 입력" onInput={props.onInput} />
										<div className="inputFocus"></div>
									</div>
								</div>
								<h4>신규 비밀번호</h4>
								<div className="box">
									<div className="inputSt1">
										<InputDelButton type="password" value={props.values.password} name="password" className="clearInput" placeholder="신규 비밀번호 입력" onInput={props.onInput} />
										<div className="inputFocus"></div>
									</div>
									<p className={props.validate['password'] ? 'txtChkOk' : 'txtChkWrong'}>{props.validateContent['password']}</p>
								</div>
								<h4>신규 비밀번호 확인</h4>
								<div className="box">
									<div className="inputSt1">
										<InputDelButton type="password" value={props.values.passwordCheck} name="passwordCheck" className="clearInput" placeholder="신규 비밀번호 재입력" onInput={props.onInput} />
										<div className="inputFocus"></div>
									</div>
									<p className={props.validate['passwordCheck'] ? 'txtChkOk' : 'txtChkWrong'}>{props.validateContent['passwordCheck']}</p>
								</div>
								<div className="btnCenter">
									{onClass !== '' && props.validate['password'] === true && props.validate['passwordCheck'] === true ?
										<a href="#this" className={`btnTy2 ${onClass}`} onClick={props.onSubmit}>저장</a> : <a href="#this" className={`btnTy2`}>저장</a>
									}
								</div>
							</div>
						</div>
						<a href="#this" className="txtHide btnLayerClose1 layerPwChange_close" onClick={props.onClear}>닫기</a>
					</div>
				</div>
			</div>
			<div className="custom_modal_background" onClick={props.onClear}></div>
		</>
	) : null;
}

function MobileForm(props) {
	const onClassSubmit = props.values.sendedMobile && props.values.confirmNo ? 'on' : '';
	const onClassSendmsg = props.values.mobile ? 'on' : '';
	return props.nowMod[1] ? (
		<>
			<div id="layerPhoneChange_wrapper" className="custom_modal_wrapper" >
				<div id="layerPhoneChange" className="popup_content custom_modal_content">
					<div className="layerBox">
						<div className="layerHd">
							<h3>휴대폰 번호 변경</h3>
						</div>
						<div className="layerCnt">
							<div className="layerCom">
								<h4>휴대폰 번호</h4>
								<div className="box telLi">
									<div className="inputSt1">
										<InputDelButton type="tel" value={props.values.mobile} name="mobile" className="clearInput" placeholder="휴대폰 번호 입력" onInput={props.onInput} />
										<div className="inputFocus"></div>
									</div>
									<a href="#this" className={`btnTy4 ${onClassSendmsg}`} onClick={props.onSend}>{!props.values.sendedMobile ? "발송" : "재발송"}</a>
								</div>
								<h4>인증번호</h4>
								<div className="box numLi">
									<div className="inputSt1">
										<InputDelButton type="tel" value={props.values.confirmNo} className="clearInput" name="confirmNo" placeholder="4자리" onInput={props.onInput} maxLength={4} />
										<div className="inputFocus"></div>
										{props.values.sendedMobile && <Timer onTimeout={props.onTimeout} startTime={props.msgSendDate} />}
									</div>
								</div>
								<div className="btnCenter">
									{props.msgTimeout === false ?
										<a href="#this" className={`btnTy2 ${onClassSubmit}`} onClick={props.onSubmit}>확인</a>
										:
										<a href="#this" className={`btnTy2`} onClick={(e) => e.preventDefault()}>확인</a>
									}
								</div>
							</div>
						</div>
						<a href="#this" className="txtHide btnLayerClose1 layerPhoneChange_close" onClick={props.onClear}>닫기</a>
					</div>
				</div>
			</div>
			<div className="custom_modal_background" onClick={props.onClear}></div>
		</>
	) : null;
}

export default withRouter(withApolloClient(SetInfo))