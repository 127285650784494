import ReactDOM from "react-dom";
import React from "react";
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { chkImageUrl } from './Library';

const CustomModal = () => { };
CustomModal.confirm = (props) => {
    const container = document.createElement("div");

    let title = props.title ? props.title : '';
    let content = props.content ? props.content : '';
    let okText = props.okText ? props.okText : '확인';
    let cancelText = props.cancelText ? props.cancelText : '취소';
    let dimmed = props.dimmed === false ? false : true;

    let handleOk = function handleOk(e) {
        e.preventDefault()
        var onOk = props.onOk;
        if (onOk) {
            onOk(e);
        }
        container.remove();
    };
    let handleCancel = function handleOk(e) {
        e.preventDefault()
        var onCancel = props.onCancel;
        if (onCancel) {
            onCancel(e);
        }
        container.remove();
    };
    // let handlerClose = function handleClose(e) {
    //     container.remove();
    // }

    let innerTitle;
    let innerContent;
    if ("string" === typeof title) {
        innerTitle = <span dangerouslySetInnerHTML={{ __html: title.replace('\n', '<br/>') }}></span>
    } else {
        innerTitle = title;
    }
    if ("string" === typeof content) {
        innerContent = <span className="txtSub1" dangerouslySetInnerHTML={{ __html: content.replace('\n', '<br/>') }}></span>;
    } else {
        innerContent = <span className="txtSub1">{content}</span>
    }

    //document.body.appendChild(container);
    document.getElementById('root').appendChild(container);
    ReactDOM.render(
        <>
            <div id="custom_modal_wrapper" className="custom_modal_wrapper" style={{ zIndex: 999 }}>
                <div className="layerAlertCom popup_content custom_modal_content">
                    <div className="layerBox">
                        <div className="layerCnt">
                            <div className="layerAlert">
                                <p>
                                    {innerTitle}
                                    {innerContent}
                                </p>
                                <div className="btnRight">
                                    <a href="#this" onClick={handleCancel} className="btnAlert2">{cancelText}</a>
                                    <a href="#this" onClick={handleOk} className="btnAlert1">{okText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dimmed === true && <div className="custom_modal_background" style={{ zIndex: 998 }} onClick={handleCancel}></div>}
        </>,
        container
    );
};

CustomModal.alert = (props) => {
    const container = document.createElement("div");

    let content = props.content ? props.content : '';
    let okText = props.okText ? props.okText : '확인';
    let dimmed = props.dimmed === false ? false : true;

    let handleOk = function handleOk(e) {
        e.preventDefault()
        var onOk = props.onOk;
        if (onOk) {
            onOk(e);
        }
        container.remove();
    };
    // let handlerClose = function handleClose(e) {
    //     container.remove();
    // }

    let innerContent;
    if ("string" === typeof content) {
        innerContent = <span dangerouslySetInnerHTML={{ __html: content.replace('\n', '<br/>') }}></span>;
    } else {
        innerContent = <span>{content}</span>
    }

    document.getElementById('root').appendChild(container);
    ReactDOM.render(
        <>
            <div className="custom_modal_wrapper" style={{ zIndex: 999 }}>
                <div className="layerAlertCom popup_content custom_modal_content">
                    <div className="layerBox">
                        <div className="layerCnt">
                            <div className="layerAlert">
                                <p>{innerContent}</p>
                                <div className="btnRight">
                                    <a href="#this" onClick={handleOk} className="btnAlert1">{okText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dimmed === true && <div className="custom_modal_background" style={{ zIndex: 998 }} onClick={handleOk}></div>}
        </>,
        container
    );
};


CustomModal.warning = (props) => {
    const container = document.createElement("div");

    let title = props.title ? props.title : '';
    let content = props.content ? props.content : '';
    let okText = props.okText ? props.okText : '확인';
    let dimmed = props.dimmed === false ? false : true;

    let handleOk = function handleOk(e) {
        e.preventDefault()
        var onOk = props.onOk;
        if (onOk) {
            onOk(e);
        }
        container.remove();
    };
    // let handlerClose = function handleClose(e) {
    //     container.remove();
    // }

    let innerTitle;
    let innerContent;
    if ("string" === typeof title) {
        innerTitle = <span dangerouslySetInnerHTML={{ __html: title.replace('\n', '<br/>') }}></span>
    } else {
        innerTitle = title;
    }
    if ("string" === typeof content) {
        innerContent = <span className="txtSub1" dangerouslySetInnerHTML={{ __html: content.replace('\n', '<br/>') }}></span>;
    } else {
        innerContent = <span className="txtSub1">{content}</span>
    }

    document.getElementById('root').appendChild(container);
    ReactDOM.render(
        <>
            <div className="custom_modal_wrapper" style={{ zIndex: 999 }}>
                <div className="layerAlertCom popup_content custom_modal_content">
                    <div className="layerBox">
                        <div className="layerCnt">
                            <div className="layerAlert">
                                <p>
                                    {innerTitle}
                                    {innerContent}
                                </p>
                                <div className="btnRight">
                                    <a href="#this" onClick={handleOk} className="btnAlert1">{okText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dimmed === true && <div className="custom_modal_background" style={{ zIndex: 998 }} onClick={handleOk}></div>}
        </>,
        container
    );
};

CustomModal.success = (props) => {
    CustomModal.alert(props);
}
CustomModal.error = (props) => {
    CustomModal.alert(props);
}
CustomModal.info = (props) => {
    CustomModal.confirm(props);
}

CustomModal.zoomImage = (props) => {
    const container = document.createElement("div");

    let images = props.images ? props.images : '';

    let handleClose = function handleClose(e) {
        container.remove();
    };

    let swiper = function swipter(e) {
        new Swiper('.swiper-container', {
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            }
        });
    };

    document.getElementById('root').appendChild(container);
    ReactDOM.render(
        <div className="custom_modal_wrapper" style={{ zIndex: 999 }}>
            <div id="layerReviewPhoto" className="layerMenuPhoto popup_content custom_modal_content">
                <div className="bgModal100" onClick={handleClose}></div>
                <div className="menuPhotoBox" style={{ pointerEvents: 'none' }}>
                    <div className="photo">
                        <div className="swiper-container">
                            <ul className="swiper-wrapper">
                                {images.map((img, k) => (
                                    <li className="swiper-slide" key={k}><img src={chkImageUrl(img)} alt="" onError={e => e.target.style.display = 'none'} /></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
                <a href="#this" onClick={handleClose} style={{ zIndex: 998 }} className="btnLyClose1 txtHide layerReviewPhoto_close">닫기</a>
            </div>
        </div>,
        container, swiper
    );
}

export default CustomModal;