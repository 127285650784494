import React from "react";
import Footer from './Footer';
import PushMark from './PushMark';
import { NavLink, withRouter } from "react-router-dom";
import { gqlErrorHandler, isLogin } from './Library';
import { withApolloClient } from './ApolloClient';
import { gql, loader } from 'graphql.macro';

const MEMBER_COUPON_LIST_QUERY = loader('../gql/member_coupon_list.gql');
const ORDER_COUNT_QUERY = gql`
	query getOrderCount($gid: Float!, $status: String) {
		gongOrders(gid: $gid, status: $status) {
			totalCount
		}
	}
`

class Mypage extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (isLogin()) {
			return (<MemberMypage  {...this.props} />)
		} else {
			return (<NonMemberMypage  {...this.props} />)
		}
	}
}
class NonMemberMypage extends React.Component {
	constructor(props) {
		super(props);
		this.goLogin = this.goLogin.bind(this);
	}
	goLogin = function goLogin(e) {
		e.preventDefault();
		localStorage.prevpath = '/mypage';
		window.location.href = '/login';
	};
	render() {
		let version = localStorage.app_version ? localStorage.app_version : '1.0.0';
		return (
			<div id="wrap">
				<div id="header" className="subHeader2">
					<div className="header">
						<h1 className="txtH1">마이페이지</h1>
						<div className="btnHr">
							<PushMark />
						</div>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts5">
						<div className="mypageSetion">
							<div className="mpLoginBox">
								<a href="#this" onClick={this.goLogin} className="loginGo"><span>반가워요</span>로그인이 필요합니다</a>
							</div>
							<div className="mpMenu">
								<ul>
									<li className="mp1"><NavLink to="/event">이벤트</NavLink></li>
									<li className="mp3"><NavLink to="/mypage/notice">공지</NavLink></li>
									<li className="mp4"><NavLink to="/mypage/faq">문의</NavLink></li>
								</ul>
							</div>
							<hr className="hrBk" />
							<div className="mpListMenu">
								<ul>
									<li><NavLink to="/mypage/terms-list">이용약관</NavLink></li>
									<li><span>현재버전<em>v {version}</em></span></li>
								</ul>
							</div>
							<div className="csNumDiv">
								<p><a href="tel:1533-2002">가보장 고객센터<strong>1533-2002</strong></a></p>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

class MemberMypage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			memberInfo: {
				//name: localStorage.member_nick ? localStorage.member_nick : '',
				email: localStorage.member_email ? localStorage.member_email : '',
				mobile: localStorage.member_mobile ? localStorage.member_mobile : ''
			},
			memberCouponCnt: 0,
			memberOrderCnt: 0
		};
		this.getMemberCounpon = this.getMemberCounpon.bind(this);
		this.getMemberOrder = this.getMemberOrder.bind(this);
	}

	componentDidMount() {
		this.getMemberCounpon();
		this.getMemberOrder();
	}

	getMemberCounpon = () => {
		if (isLogin()) {
			this.props.client.query({
				query: MEMBER_COUPON_LIST_QUERY,
				fetchPolicy: 'network-only',
				variables: { valid: true, gid: process.env.REACT_APP_SERVICE_GID },
			}).then((result) => {
				this.setState({
					memberCouponCnt: result.data.memberCouponList.length
				});
			}).catch((err) => { gqlErrorHandler(err) });
		} else {
			this.setState({
				memberCouponCnt: 0
			});
		}
	}
	getMemberOrder = () => {
		if (isLogin()) {
			this.props.client.query({
				query: ORDER_COUNT_QUERY,
				fetchPolicy: 'network-only',
				variables: { gid: process.env.REACT_APP_SERVICE_GID, status: "FINISH" }, //처리완료기준
			}).then((result) => {
				this.setState({
					memberOrderCnt: result.data.gongOrders.totalCount
				});
			}).catch((err) => { gqlErrorHandler(err) });
		} else {
			this.setState({
				memberOrderCnt: 0
			});
		}
	}

	render() {
		const { memberInfo, memberCouponCnt, memberOrderCnt } = this.state
		let version = localStorage.app_version ? localStorage.app_version : '1.0.0';
		return (
			<div id="wrap">
				<div id="header" className="subHeader2">
					<div className="header">
						<h1 className="txtH1">마이페이지</h1>
						<div className="btnHr">
							<PushMark />
						</div>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts5">
						<div className="mypageSetion">
							<div className="mpLoginBox">
								<span className="name">{memberInfo.mobile.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")}</span>
								<ul>
									<li>{memberInfo.email}</li>
								</ul>
								<NavLink to="/mypage/set-info" className="btnSetup"><img src="/img/common/btn_setup.png" alt="설정" /></NavLink>
							</div>
							<div className="mpMenu">
								<div className="myMenu">
									<NavLink to="/mypage/coupon"><strong>{memberCouponCnt}</strong>쿠폰</NavLink>
									<NavLink to="/order"><strong>{memberOrderCnt}</strong>주문내역</NavLink>

								</div>
								<ul>
									<li className="mp1"><NavLink to="/event">이벤트</NavLink></li>
									<li className="mp2"><NavLink to="/mypage/bookmark">찜</NavLink></li>
									<li className="mp3"><NavLink to="/mypage/notice">공지</NavLink></li>
									<li className="mp4"><NavLink to="/mypage/faq">문의</NavLink></li>
								</ul>
							</div>
							<hr className="hrBk" />
							<div className="mpListMenu">
								<ul>
									<li><NavLink to="/mypage/set-address">내 주소 설정</NavLink></li>
									<li><NavLink to="/mypage/set-message">알림/푸쉬</NavLink></li>
									<li><NavLink to="/mypage/terms-list">이용약관</NavLink></li>
									<li><span>현재버전<em>v {version}</em></span></li>
								</ul>
							</div>
							<div className="csNumDiv">
								<p><a href="tel:1533-2002">가보장 고객센터<strong>1533-2002</strong></a></p>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default withApolloClient(withRouter(Mypage))