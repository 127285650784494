import React, { useRef, useEffect } from "react";
import { Route, useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { gqlErrorHandler, chkImageUrl, bodyScrollLock } from './Library'
import { useQuery, useMutation } from '@apollo/client';
import { Spin } from 'antd'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import BottomScrollListener from 'react-bottom-scroll-listener'
import { LoadingOutlined } from '@ant-design/icons'
import $ from 'jquery';
import Modal from './CustomModal'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const reviewListGql = loader('../gql/review_list.gql')
const delReviewGql = loader('../gql/del_review.gql')

const Review = (props) => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	let history = useHistory();
	let match = useRouteMatch();

	return <>
		<Route path={`${match.path}`}>
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<a href="#this" className="txtHide btnHback" onClick={(e) => { e.preventDefault(); history.goBack(); }}>뒤로가기</a>
						<h1>리뷰목록</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<ReviewList />
					</div>
				</div>
			</div>
		</Route>
		<Route path={`${match.path}/image/:token/:position`}>
			<ReviewImage />
		</Route>
	</>
}
const ReviewList = (props) => {
	const doFetchMore = useRef(true)
	useEffect(() => {
		//기타버튼 레이어 show,hide
		$(document).on('click', function (e) {
			e.preventDefault();
			if (!$(e.target).hasClass('btnEtc')) {
				//외부클릭시모두닫기
				$('.btnEtc').removeClass('on').next().slideUp('fast');
			} else {
				//선택레이어토클
				$(e.target).toggleClass('on').next().slideToggle('fast');
				//다른레이어닫기
				$('.btnEtc').not($(e.target)).removeClass('on').next().slideUp('fast');
			}
		})
		return () => $(document).off('click')
	})
	let history = useHistory();
	let match = useRouteMatch();

	const values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		isMine: true,
		first: 15,
	}

	const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

	const { error, data, fetchMore, refetch } = useQuery(reviewListGql, { variables: values, fetchPolicy: 'cache-and-network' });
	const [delReview] = useMutation(delReviewGql)

	if (error) { gqlErrorHandler(error); return null; }
	if (!data)
		return (
			<div style={{ paddingLeft: '48%', paddingTop: '50%', marginTop: 5 }}>
				<Spin tip="" indicator={antIcon} />
			</div>
		)
	const handleFetchMore = async (fm, data) => {
		if (data.gongReviewList.pageInfo.hasNextPage === false) return
		if (doFetchMore.current === false) return

		doFetchMore.current = false

		await fm({
			variables: {
				cursor: data.gongReviewList.pageInfo.endCursor
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongReviewList.edges;
				const pageInfo = fetchMoreResult.gongReviewList.pageInfo;

				return newEdges.length
					? {
						gongReviewList: {
							__typename: previousResult.gongReviewList.__typename,
							edges: [...previousResult.gongReviewList.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		})

		doFetchMore.current = true
	}
	const handleToOrder = (pk, e) => {
		e.preventDefault();
		window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/shop/${pk}?token=${localStorage.getItem('access_token')}`);
	}
	let rwin
	let timer
	const handleToReview = (token, e) => {
		e.preventDefault();

		var menuLayer = $('.review-list .review .menu ul');
		menuLayer.hide();
		rwin = window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/order/review/${token}/?token=${localStorage.getItem('access_token')}`);
		timer = window.setInterval(ifWindowClosed, 500)
	}
	const ifWindowClosed = () => {
		if (rwin.closed === true) {
			refetch() // 수정된 내역 반영하기
			window.clearInterval(timer);
		}
	}
	const handleDelReview = (pk, e) => {
		e.preventDefault();
		Modal.confirm({
			title: "작성하신 리뷰를 삭제하겠습니까?",
			content: "삭제 후 복구 및 재작성은 불가능 합니다.",
			okText: "확인",
			cancelText: "취소",
			onOk: () => {
				handleDeleteOK(pk)
			},
			onCancel() { },
		});
	}
	const handleDeleteOK = (pk) => {
		delReview({ variables: { pk } })
			.then((res) => {
				refetch()
			})
			.catch(error => gqlErrorHandler(error))
			.then(() => {
				Modal.alert({ content: '리뷰삭제가 완료 되었습니다.' })
			})
	}
	return (
		<div className="reviewList">
			<ul>
				{data.gongReviewList.edges.length > 0
					? <BottomScrollListener onBottom={() => handleFetchMore(fetchMore, data)}>
						{data.gongReviewList.edges.map((item, k) => (
							<li key={k}>
								<dl>
									<dt><span onClick={(e) => { handleToOrder(item.node.shop.pk, e) }}>{item.node.shop.name}</span></dt>
									<dd>{dayjs(item.node.createdAt, 'YYYY.MM.DD HH:mm').format('YYYY년 MM월 DD일')}</dd>
								</dl>
								<a href="#this" className="txtHide btnEtc">기타</a>
								<div className="layerEtc">
									<a href="#this" onClick={(e) => { handleToReview(item.node.token, e) }}>수정</a>
									<a href="#this" onClick={(e) => { handleDelReview(item.node.pk, e) }}>삭제</a>
								</div>
								<dl className="odMenu">
									<dt>주문메뉴</dt>
									<dd>{item.node.order.goodsName}</dd>
								</dl>
								{item.node.imgs.length > 0 &&
									<div className={`photo type${item.node.imgs.length < 3 ? item.node.imgs.length : 3}`}>
										<ul>
											{item.node.imgs.map((img, k) =>
												k < 3
													? <li key={k} className={k === 2 && item.node.imgs.length > 3 ? 'morePhoto' : ''}>
														<a href="#this" onClick={(e) => { e.preventDefault(); history.push(`${match.url}/image/${item.node.token}/${k}`) }}>
															<img src={chkImageUrl(img.imgUrl)} onError={e => e.target.style.display = 'none'} alt="" />
														</a>
													</li>
													: null
											)}
										</ul>
									</div>
								}
								<p style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>{item.node.content}</p>
								{item.node.replyContent &&
									<div className="ownerReply">
										<dl>
											<dt>사장님</dt>
											<dd>{item.node.replyCreatedAt && dayjs(item.node.replyCreatedAt, 'YYYY.MM.DD HH:mm').format('YYYY년 MM월 DD일')}</dd>
										</dl>
										<p style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>{item.node.replyContent}</p>
									</div>
								}
							</li>
						))}
						{data.gongReviewList.pageInfo.hasNextPage === true &&
							<div style={{ paddingLeft: '48%', marginTop: 5 }}>
								<Spin tip="" indicator={antIcon} />
							</div>
						}
					</BottomScrollListener>
					: <li className="noData">
						<p>리뷰내역이 없습니다.</p>
					</li>
				}
			</ul>
		</div>
	)
}

const ReviewImage = (props) => {
	let history = useHistory();
	let { token, position } = useParams();
	const values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		isMine: true,
		token,
	}
	useEffect(() => {
		bodyScrollLock.lock()
		if ($('.layerMenuPhoto').length) {
			new Swiper('.swiper-container', {
				pagination: {
					el: ".swiper-pagination",
					type: "fraction",
				},
				initialSlide: position
			})
			$('.layerMenuPhoto').animate({ opacity: 1 }, 'fast')
		}
		return () => bodyScrollLock.unlock()
	})
	const hanbleClose = (e) => {
		e.preventDefault()
		$('.layerMenuPhoto').animate({ opacity: 0 }, 'fast', history.goBack)
	}

	const { error, data } = useQuery(reviewListGql, { variables: values, fetchPolicy: 'cache-and-network' });
	if (error) { gqlErrorHandler(error); return null; }

	const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />
	if (!data)
		return (
			<div id="overlay" style={{ display: 'grid', placeItems: 'center', backgroundColor: 'transparent' }}>
				<Spin tip="" indicator={antIcon} />
			</div>
		)

	return <div className="layerMenuPhoto" style={{ opacity: 0 }}>
		<div className="bgModal100"></div>
		<div className="menuPhotoBox">
			<div className="photo">
				<div className="swiper-container">
					<ul className="swiper-wrapper">
						{data.gongReviewList.edges[0].node.imgs.map((img, k) => (
							<li className="swiper-slide" key={k}><img src={chkImageUrl(img.imgUrl)} alt="" onError={e => e.target.style.display = 'none'} /></li>
						))}
					</ul>
				</div>
			</div>
			<div className="swiper-pagination"></div>
		</div>
		<a href="#this" className="btnLyClose1 txtHide" onClick={hanbleClose}>닫기</a>
	</div>
}
export default Review