import React from "react";
import "./App.css";
import { Switch } from "react-router-dom";
import Main from "./components/Main";
import Search from "./components/Search";
import Shop from "./components/Shop";
import Cart from "./components/Cart";
import Login from "./components/Login";
import Mypage from "./components/Mypage";
import Order from "./components/Order";
import OrderDetail from "./components/OrderDetail";
import SetAddress from "./components/SetAddress";
import PermissionAddr from "./components/PermissionAddr";
import Notice from "./components/Notice";
import Message from "./components/Message";
import SetMessage from "./components/SetMessage";
import TermsList from "./components/TermsList";
import Faq from "./components/Faq";
import Inquiry from "./components/Inquiry";
import Review from "./components/Review";
import Coupon from "./components/Coupon";
import PermissionInfo from "./components/PermissionInfo";
import SetInfo from "./components/SetInfo";
import MobileConfirm from "./components/MobileConfirm";
import Bookmark from "./components/Bookmark";
import Join from "./components/Join";
import Event from "./components/Event";
import axios from "axios";
import { ApolloProvider } from '@apollo/client'
import client, { locAddressMainVar } from './components/ApolloClient'
import { isLogin, setLocAddressMain, gqlErrorHandler } from './components/Library'
import { loader } from 'graphql.macro'
import AppRoute from './components/AppRoute'

const memberAddressGql = loader('./gql/member_address.gql')
const addAddressGql = loader('./gql/add_address.gql')

export default class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = { tokenOK: true, addressOK: false }

		let appVersion = new URLSearchParams(window.location.search).get("appver");
		let osVersion = new URLSearchParams(window.location.search).get("osver");
		if (appVersion) localStorage.app_version = appVersion;
		if (osVersion) localStorage.os_version = osVersion;

		let waitRefresh = null /* 토큰갱신 기다리기 */
		// 토큰 갱신
		if (localStorage.access_token && localStorage.refresh_token
			&& window.location.pathname !== '/login' && window.location.pathname !== '/join'
			&& window.location.pathname !== '/mobile-confirm/login' && window.location.pathname !== '/mobile-confirm/join') {
			this.state.tokenOK = false
			const authStr = 'Basic ' + btoa(localStorage.client_id + ':' + localStorage.client_secret);
			waitRefresh = axios
				.post(`${process.env.REACT_APP_SERVER_REST}/v2/oauth/member/token`,
					`grant_type=refresh_token&refresh_token=${localStorage.refresh_token}`,
					{
						headers: { 'authorization': authStr }
					})
				.then(res => {
					if (res.data.access_token && res.data.refresh_token) {
						localStorage.access_token = res.data.access_token;
						localStorage.refresh_token = res.data.refresh_token;
						localStorage.token_expires = Math.round(new Date().getTime() / 1000) + parseInt(res.data.expires_in);
					} else {
						localStorage.access_token = '';
						localStorage.refresh_token = '';
						localStorage.token_expires = '';

						/* global AppScheme */
						typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://logout`);
					}
					this.setState({ tokenOK: true })
				})
				.catch(res => {
					if (res.response) {
						localStorage.access_token = '';
						localStorage.refresh_token = '';
						localStorage.token_expires = '';

						typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://logout`);
					}
					this.setState({ tokenOK: true })
				})
		} else {
			waitRefresh = Promise.resolve()
		}
		waitRefresh
			.then(() => this.syncAddressMain()) /* 토큰갱신과 비동기적으로 진행 시 서버주소를 가끔 받아오지 못함, 갱신 끝나고 처리하도록. */
			.then(() => this.setState({ addressOK: true }))
	}

	syncAddressMain = async () => {
		/**
		 * 로그인 상태에서만 주소동기화 한다.
		 * 서버가 주가됨.
		 */
		if (!isLogin()) return

		const address = await client
			.query({
				query: memberAddressGql,
				variables: { isMain: true },
				fetchPolicy: 'network-only',
			})
			.catch((err) => gqlErrorHandler(err))

		const remoteAddr = address.data.memberAddressList.edges[0] ? address.data.memberAddressList.edges[0].node : null
		const localAddr = locAddressMainVar()

		if (remoteAddr) { /* 서버에 있고, 로컬에는 없거나 다르면 로컬로 동기화한다. */
			if (!localAddr || localAddr.addr1 !== remoteAddr.addr1 || localAddr.addr2 !== remoteAddr.addr2 || localAddr.dong !== remoteAddr.region.dong) {
				const newLocalAddr = {
					...remoteAddr,
					sido: remoteAddr.region ? remoteAddr.region.sido : null,
					gugun: remoteAddr.region ? remoteAddr.region.gugun : null,
					dong: remoteAddr.region ? remoteAddr.region.dong : null,
				}
				setLocAddressMain(newLocalAddr)
				console.log("set local addr")
			}
		} else if (localAddr) { /* 서버에 없고, 로컬에 있으면 서버로 업뎃한다. */
			const newRemoteAddr = {
				...localAddr,
				isMain: true,
				region: localAddr.bCode,
			}
			client
				.mutate({
					mutation: addAddressGql,
					variables: newRemoteAddr,
				})
				.then(() => console.log("set remote addr"))
				.catch((err) => gqlErrorHandler(err))
		}
	}

	render() {
		return this.state.tokenOK && this.state.addressOK &&
			<ApolloProvider client={client}>
				<Switch>
					{/* app_version있는지로 앱인지 브라우저인지를 판단한다. 브라우저 시 주소설정필요없이메인접근허용. */}
					<AppRoute notChkAddr={localStorage.getItem("app_version") === null} exact path="/">
						<Main />
					</AppRoute>
					<AppRoute path="/event">
						<Event />
					</AppRoute>
					<AppRoute path={['/search/:keyword/:category', '/search/:keyword', '/search']}>
						<Search />
					</AppRoute>
					<AppRoute path="/login">
						<Login />
					</AppRoute>
					<AppRoute path="/join">
						<Join />
					</AppRoute>
					<AppRoute path="/mobile-confirm/:mode">
						<MobileConfirm />
					</AppRoute>
					<AppRoute isPrivate path="/order">
						<Order />
					</AppRoute>
					<AppRoute isPrivate path="/order-detail/:id">
						<OrderDetail />
					</AppRoute>
					<AppRoute path={['/shop/:category', '/shop']}>
						<Shop />
					</AppRoute>
					<AppRoute exact path="/mypage">
						<Mypage />
					</AppRoute>
					<AppRoute notChkAddr path="/mypage/set-address">
						<SetAddress />
					</AppRoute>
					<AppRoute notChkAddr path="/permission-addr">
						<PermissionAddr />
					</AppRoute>
					<AppRoute path="/mypage/notice">
						<Notice />
					</AppRoute>
					<AppRoute isPrivate path="/mypage/message">
						<Message />
					</AppRoute>
					<AppRoute isPrivate path="/mypage/set-message">
						<SetMessage />
					</AppRoute>
					<AppRoute path="/mypage/terms-list">
						<TermsList />
					</AppRoute>
					<AppRoute path={['/mypage/faq']}>
						<Faq />
					</AppRoute>
					<AppRoute path={['/mypage/inquiry']}>
						<Inquiry />
					</AppRoute>
					<AppRoute path="/permission-info">
						<PermissionInfo />
					</AppRoute>
					<AppRoute isPrivate exact path="/mypage/set-info">
						<SetInfo />
					</AppRoute>
					<AppRoute isPrivate path="/mypage/bookmark">
						<Bookmark />
					</AppRoute>
					<AppRoute path="/mypage/coupon">
						<Coupon />
					</AppRoute>
					<AppRoute isPrivate path="/mypage/review">
						<Review />
					</AppRoute>
					<AppRoute isPrivate path="/cart">
						<Cart />
					</AppRoute>
				</Switch>
			</ApolloProvider>
	}
}
