import React, { useState, useEffect } from "react";
import { NavLink, Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { withApolloClient } from './ApolloClient';
import dateFormat from 'dateformat';
import Modal from './CustomModal';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { gqlErrorHandler, isLogin } from './Library';
import { Query, Mutation } from '@apollo/client/react/components';
import InputDelButton from "./InputDelButton";
import $ from 'jquery';

const formatter = new Intl.NumberFormat()
const MEMBER_COUPON_LIST_QUERY = loader('../gql/member_coupon_list.gql');
const COUPON_LIST_QUERY = loader('../gql/coupon_list.gql');
const COUPON_MUTATION = loader('../gql/set_member_coupon.gql');
const reduceTypeList = { 'PERCENT': '%', 'PRICE': '원' }

const Coupon = (props) => {
	let match = useRouteMatch();
	const [couponCode, setCouponCode] = useState('');

	const { listen } = useHistory()

	useEffect(() => {
		const unlisten = listen((location) => {
			$(".tabSectoin").hide().css({ 'margin-left': 30, 'opacity': 0 }).show().stop().animate({ 'margin-left': 0, opacity: 1 }, 500);
		});
		return unlisten
	}, [listen])

	return (
		<div id="wrap">
			<div id="header" className="subHeader2">
				<div className="header">
					<div className="btnList">
						<NavLink to="/mypage" className="txtHide">뒤로가기</NavLink>
					</div>
					<h1>쿠폰</h1>
				</div>
			</div>
			<div id="container">
				<Switch>
					<Route exact path={`${match.path}`}>
						<div id="contents">
							<div className="comSch2">
								<div className="tabUi2">
									<ul>
										<li><button className="on" data-conts="allCoupon">전체 쿠폰</button></li>
										<li><NavLink to="/mypage/coupon/expire"><button data-conts="couponEnd">사용완료/기간 만료</button></NavLink></li>
									</ul>
								</div>
								<div className="sch codeInput" id="sch">
									<div className="inputSt1">
										<InputDelButton type="text" name="coupon" value={couponCode} className="clearInput" placeholder="쿠폰 코드를 입력해주세요" onInput={(mode, val) => setCouponCode(val)} />
										<div className="inputFocus"></div>
									</div>
									<Mutation
										mutation={COUPON_MUTATION}
										variables={{ code: couponCode }}
										onCompleted={(data) => {
											if (data.couponIssue.result) {
												setCouponCode('');
												Modal.success({
													content: "쿠폰이 발급되었습니다.",
													onOk: () => {
														window.location.replace('/mypage/coupon')
													}
												});
											} else {
												Modal.warning({
													title: "쿠폰 발급 오류",
													content: data.couponIssue.msg,
												});
											}
										}}
									>
										{
											(couponIssue, { data, loading, error }) => {
												if (error) { gqlErrorHandler(error); return null }
												if (couponCode.trim() === '') {
													return (
														<a href="#this" className="btnTy2" onClick={(e) => e.preventDefault()}>등록</a>
													);
												} else {
													return (
														<a href="#this" className="btnTy2 on" onClick={(e) => { e.preventDefault(); couponIssue(); }}>등록</a>
													);
												}
											}
										}
									</Mutation>

								</div>
							</div>
							<div className="mycouponList">
								<div className="tabSectoin on" id="allCoupon">
									<hr className="hrBk" />
									{isLogin() && <MyCouponList {...props} />}
									<CouponList {...props} />
								</div>
							</div>
						</div>

					</Route>
					<Route path={`${match.path}/expire`}>
						<div id="contents">
							<div className="comSch2">
								<div className="tabUi2">
									<ul>
										<li><NavLink to="/mypage/coupon"><button data-conts="allCoupon">전체 쿠폰</button></NavLink></li>
										<li><button className="on" data-conts="couponEnd">사용완료/기간 만료</button></li>
									</ul>
								</div>
							</div>
							<div className="mycouponList">
								<div className="tabSectoin" id="couponEnd">
									<div className="topNoti">
										<p>최근 한 달 내에 만료된 쿠폰을 확인하실 수 있습니다.</p>
									</div>
									<ExpiredCouponList {...props} />
								</div>
							</div>
						</div>
					</Route>
				</Switch>
			</div>
		</div >
	)
}

class MyCouponList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			memberCouponList: [],
			memberCouponCount: 0
		};
		this.getMemberCounpon = this.getMemberCounpon.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.getMemberCounpon();
		$(".tabSectoin").show().stop().animate({ 'margin-left': 0, opacity: 1 }, 500);
	}
	getMemberCounpon = () => {
		if (isLogin()) {
			this.props.client.query({
				query: MEMBER_COUPON_LIST_QUERY,
				variables: { valid: true, gid: process.env.REACT_APP_SERVICE_GID },
				fetchPolicy: 'network-only',
			}).then((result) => {
				this.setState({
					memberCouponCount: result.data.memberCouponList.length,
					memberCouponList: result.data.memberCouponList
				});
			}).catch((err) => { gqlErrorHandler(err) });
		} else {
			this.setState({
				memberCouponCount: 0,
				memberCouponList: []
			});
		}
	}

	render() {
		const { memberCouponList, memberCouponCount } = this.state;
		return (
			<>
				<h3>보유 쿠폰 <em>{memberCouponCount}</em>장</h3>
				{
					memberCouponCount === 0 ? <div className="coupon">
						<div className="noData">
							<p>사용 가능한 쿠폰이 없습니다.</p>
						</div>
					</div> : <>
						{
							memberCouponList.map((v, k) => (
								<MyCoupon key={k} coupon={v.coupon} />
							))
						}
					</>
				}
			</>
		)
	}

}

class CouponList extends React.Component {
	constructor(props) {
		super(props);
		this.handleFetchMore = this.handleFetchMore.bind(this);
		this.doFetchMore = React.createRef(true);
	}

	handleFetchMore = (fm, data, searchVariables) => {
		if (data.couponList.pageInfo.hasNextPage === false) return
		if (this.doFetchMore === false) return
		this.doFetchMore.current = false;

		fm({
			variables: searchVariables,
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.couponList.edges;
				const pageInfo = fetchMoreResult.couponList.pageInfo;
				return newEdges.length
					? {
						couponList: {
							__typename: previousResult.couponList.__typename,
							edges: [...previousResult.couponList.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		}).then(() => this.doFetchMore.current = true);
	}

	render() {
		const searchVariables = {
			gid: process.env.REACT_APP_SERVICE_GID
		};
		return (
			<>
				<h3>발급 가능 쿠폰</h3>
				<Query
					query={COUPON_LIST_QUERY}
					variables={searchVariables}
				>
					{({ loading, error, data, fetchMore }) => {
						if (loading) return null
						if (error) { gqlErrorHandler(error); return null }
						searchVariables.cursor = data.couponList.pageInfo.endCursor;
						const entries = data.couponList.edges || [];
						if (entries.length === 0) {
							return (
								<div className="coupon">
									<div className="noData">
										<p>발급 가능한 쿠폰이 없습니다.</p>
									</div>
								</div>
							)
						}
						return (
							<BottomScrollListener onBottom={() => this.handleFetchMore(fetchMore, data, searchVariables)}>
								{
									entries.map((v, k) => (
										<IssuanceCoupon key={k} coupon={v.node} />
									))
								}
							</BottomScrollListener>
						)
					}}
				</Query>

			</>
		)
	}
}
class MyCoupon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			coupon: this.props.coupon,
			flip: false
		}
		this.handleFlip = this.handleFlip.bind(this);
		this.handleToOrder = this.handleToOrder.bind(this);
	}
	handleFlip = (target) => {
		this.setState({
			flip: !this.state.flip
		}, () => {
			if (this.state.flip) {
				$(target.parentElement).find('.front').hide();
				$(target.parentElement).find('.back').fadeIn();
			} else {
				$(target.parentElement).find('.back').hide();
				$(target.parentElement).find('.front').fadeIn();
			}
		});
	}
	handleToOrder = (id, e) => {
		e.preventDefault();
		const tokenStr = localStorage.getItem('access_token') ? `?token=${localStorage.getItem('access_token')}` : ''
		window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/shop/${id}${tokenStr}`)
	}
	render() {
		const { coupon, flip } = this.state;
		const isIndvidualCoupon = coupon.shop && coupon.shop.pk ? true : false;
		const description = isIndvidualCoupon ? coupon.content : coupon.infoTerms;
		const issuePersonQty = coupon.issuePerson === 0 ? '무제한' : (coupon.issuePerson + '장');
		const activeClass = flip ? 'active' : '';
		return (
			<div className="coupon">
				<div className={`section ${activeClass}`}>
					<div id={`front_${coupon.pk}`} onClick={(e) => this.handleFlip(e.currentTarget)} className="group front">
						<div className="date">
							<span>{coupon.infoDate}</span>
						</div>
						<h4>{coupon.name}</h4>
						<strong className="price">{formatter.format(coupon.discount)}{reduceTypeList[coupon.reduceType]}</strong>
						<p className="desc">
							최소 주문 금액 : {formatter.format(coupon.minSpent)}원시 정말 사용 가능
							{description}
						</p>
					</div>
					<div id={`back_${coupon.pk}`} onClick={(e) => this.handleFlip(e.currentTarget)} className="group back">
						<div className="date">
							<span>{coupon.infoDate}</span>
						</div>
						<ul>
							<li>- 결제 조건 :  {coupon.infoTerms}</li>
							<li>- 최소주문금액 : 최소 {formatter.format(coupon.minSpent)}원 이상 결제시 사용 가능합니다.</li>
							{/* {!isIndvidualCoupon && <li>가능 카테고리 : {coupon.category && coupon.category.pk ? coupon.category.name : '전체'}</li>} */}
							<li>- 사용조건 : 다른 쿠폰과 중복 사용하실 수 없습니다.</li>
							<li>- 1인당 발급수량 : {issuePersonQty}</li>
							<li>- 쿠폰 발급기간 : {dateFormat(coupon.issueStartAt, "yyyy-mm-dd")} ~ {dateFormat(coupon.issueEndAt, "yyyy-mm-dd")}</li>
							<li>- 쿠폰 유효기간 : {dateFormat(coupon.useStartAt, "yyyy-mm-dd")} ~ {dateFormat(coupon.useEndAt, "yyyy-mm-dd")}</li>
						</ul>
					</div>
					{
						isIndvidualCoupon ?
							<a href="#this" className="btnUse" onClick={(e) => this.handleToOrder(coupon.shop.pk, e)}>쿠폰 사용하기</a>
							:
							<NavLink to={`${coupon.category && coupon.category.pk ? '/shop/' + coupon.category.pk : '/shop'}?pm=${coupon.pg}`} className="btnUse">쿠폰 사용하기</NavLink>
					}
				</div>
			</div>
		)
	}
}

class IssuanceCoupon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			coupon: this.props.coupon,
			flip: false
		}
		this.handleFlip = this.handleFlip.bind(this);
	}
	handleFlip = (target) => {
		this.setState({
			flip: !this.state.flip
		}, () => {
			if (this.state.flip) {
				$(target.parentElement).find('.front').hide();
				$(target.parentElement).find('.back').fadeIn();
			} else {
				$(target.parentElement).find('.back').hide();
				$(target.parentElement).find('.front').fadeIn();
			}
		});
	}
	render() {
		const { coupon, flip } = this.state;
		const isIndvidualCoupon = coupon.shop && coupon.shop.pk ? true : false;
		const description = isIndvidualCoupon ? coupon.content : coupon.infoTerms;
		const remianQty = coupon.issueTotal === 0 ? '무제한' : <><em>{coupon.issueCount < coupon.issueTotal ? parseInt(coupon.issueTotal) - parseInt(coupon.issueCount) : 0}</em>/{coupon.issueTotal}</>;
		const issuePersonQty = coupon.issuePerson === 0 ? '무제한' : (coupon.issuePerson + '장');
		const activeClass = flip ? 'active' : '';

		if (coupon.issueTotal !== 0 && (parseInt(coupon.issueTotal) - parseInt(coupon.issueCount)) < 1) {
			return (
				<div className="coupon wrong">
					<div className="section">
						<div id={`front_${coupon.pk}`} className="group front">
							<div className="date">
								<span>{coupon.infoDate}</span>
							</div>
							<h4>{coupon.name}</h4>
							<strong className="price">{formatter.format(coupon.discount)}{reduceTypeList[coupon.reduceType]}</strong>
							<p className="desc">
								쿠폰 수량이 모두 소진되었습니다.
							</p>
						</div>
					</div>
					<a href="#this" className="txtHide btnDown" onClick={(e) => { e.preventDefault(); }}>쿠폰 다운로드</a>
				</div>
			)
		} else {
			return (
				<div className="coupon">
					<div className={`section ${activeClass}`}>
						<div id={`front_${coupon.pk}`} onClick={(e) => this.handleFlip(e.currentTarget)} className="group front">
							<div className="date">
								<span>{coupon.infoDate}</span>
							</div>
							<h4>{coupon.name}</h4>
							<strong className="price">{formatter.format(coupon.discount)}{reduceTypeList[coupon.reduceType]}</strong>
							<p className="desc">
								최소 주문 금액 : {formatter.format(coupon.minSpent)}원<br />{description}
							</p>
							<span className="useCp">남은 쿠폰 수 : {remianQty}</span>
						</div>
						<div id={`back_${coupon.pk}`} onClick={(e) => this.handleFlip(e.currentTarget)} className="group back">
							<div className="date">
								<span>{coupon.infoDate}</span>
							</div>
							<ul>
								<li>- 결제 조건 :  {coupon.infoTerms}</li>
								<li>- 최소주문금액 : 최소 {formatter.format(coupon.minSpent)}원 이상 결제시 사용 가능합니다.</li>
								{/* {!isIndvidualCoupon && <li>- 가능 카테고리 : {coupon.category && coupon.category.pk ? coupon.category.name : '전체'}</li>} */}
								<li>- 사용조건 : 다른 쿠폰과 중복 사용하실 수 없습니다.</li>
								<li>- 1인당 발급수량 : {issuePersonQty}</li>
								<li>- 쿠폰 발급기간 : {dateFormat(coupon.issueStartAt, "yyyy-mm-dd")} ~ {dateFormat(coupon.issueEndAt, "yyyy-mm-dd")}</li>
								<li>- 쿠폰 유효기간 : {dateFormat(coupon.useStartAt, "yyyy-mm-dd")} ~ {dateFormat(coupon.useEndAt, "yyyy-mm-dd")}</li>
							</ul>
							<div className="txtHide btnShow">뒤집기</div>
						</div>
						<Mutation
							mutation={COUPON_MUTATION}
							variables={{ cid: coupon.pk }}
							onCompleted={(data) => {
								if (data.couponIssue.result) {
									Modal.success({
										content: "쿠폰이 발급되었습니다.",
										onOk: () => {
											window.location.replace('/mypage/coupon')
										}
									});
								} else {
									Modal.warning({
										title: "쿠폰 발급 오류",
										content: data.couponIssue.msg,
									});
								}
							}}
						>
							{
								(couponIssue, { error }) => {
									if (error) { gqlErrorHandler(error); return null }
									return (
										<a href="#this" className="btnDown" onClick={(e) => { e.preventDefault(); couponIssue(); }}>쿠폰 다운로드</a>
									);
								}
							}
						</Mutation>
					</div>
				</div>
			)
		}

	}
}


class ExpiredCouponList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expiredCouponList: [],
			expiredCouponCount: 0
		};
		this.getMemberCounpon = this.getMemberCounpon.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.getMemberCounpon();
		$(".tabSectoin").show().stop().animate({ 'margin-left': 0, opacity: 1 }, 500);
	}
	getMemberCounpon = () => {
		if (isLogin()) {
			this.props.client.query({
				query: MEMBER_COUPON_LIST_QUERY,
				variables: { valid: false, gid: process.env.REACT_APP_SERVICE_GID },
			}).then((result) => {
				this.setState({
					expiredCouponList: result.data.memberCouponList,
					expiredCouponCount: result.data.memberCouponList.length,
				});
			}).catch((err) => { gqlErrorHandler(err) });
		} else {
			this.setState({
				expiredCouponList: [],
				expiredCouponCount: 0
			});
		}
	}
	render() {
		if (isLogin()) {
			const { expiredCouponList, expiredCouponCount } = this.state;
			return (
				<>
					{
						expiredCouponCount === 0 && <div className="coupon wrong">
							<div className="noData">
								<p>사용완료 / 기간 만료된 쿠폰이 없습니다.</p>
							</div>
						</div>
					}
					{
						expiredCouponCount > 0 && expiredCouponList.map((v, k) => (
							<ExpiredCoupon key={k} vo={v} />
						))
					}
				</>
			)
		} else {
			return (
				<div className="coupon wrong">
					<div className="noData">
						<p>로그인이 필요합니다.</p>
					</div>
				</div>

			)
		}
	}
}

class ExpiredCoupon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vo: this.props.vo,
			coupon: this.props.vo.coupon
		}
	}
	render() {
		const reduceTypeList = { 'PERCENT': '%', 'PRICE': '원' }
		const { vo, coupon } = this.state;
		return (
			<div className="coupon wrong">
				<div className="section">
					<div className="group front">
						<div className="date">
							<span>{coupon.infoDate}</span>
						</div>
						<h4>{coupon.name}</h4>
						<strong className="price">{formatter.format(coupon.discount)}{reduceTypeList[coupon.reduceType]}</strong>
						<p className="desc">
							{vo.used ? "사용 완료" : "사용 기간 만료"}
						</p>
					</div>
					<a href="#this" className="btnDown">쿠폰 사용 불가</a>
				</div>
			</div>
		)

	}
}

export default withApolloClient(Coupon);