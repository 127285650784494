import React, { useEffect } from "react"
import { LoadingCenter } from './Loading'
import Modal from './CustomModal'
import { useHistory, useParams } from "react-router-dom"
import { useMutation, useQuery } from '@apollo/client'
import { gqlErrorHandler, openCart } from './Library'
import _ from 'lodash'
import $ from 'jquery'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { loader } from 'graphql.macro'

dayjs.extend(customParseFormat)

const orderListGql = loader('../gql/order_list.gql')
const orderRecartGql = loader('../gql/order_recart.gql')
const formatter = new Intl.NumberFormat()

export default function () {
	const history = useHistory()

	useEffect(() => {
		$('#layerOrderDetail_background').animate({ opacity: .6 }, 'fast')
		const body = document.querySelector('body')
		const oldOverflow = body.style.overflow

		body.style.overflow = "hidden"
		return () => {
			body.style.overflow = oldOverflow
		}
	}, [])

	useEffect(() => {
		$('#layerOrderDetail').length && $('#layerOrderDetail').animate({ opacity: 1 }, 'fast')
	})

	const [orderRecart] = useMutation(orderRecartGql)

	const { id: pk } = useParams()
	const { error, data } = useQuery(orderListGql, { variables: { gid: process.env.REACT_APP_SERVICE_GID, pk }, fetchPolicy: 'network-only' });
	if (error) { gqlErrorHandler(error); return null; }

	const order = data?.gongOrders?.edges?.[0]?.node

	const handleRecart = (order, e) => {
		e.preventDefault()
		Modal.confirm({
			title: "해당 주문 내역과 동일하게 주문하시겠습니까?",
			okText: "확인",
			cancelText: "취소",
			onOk() {
				orderRecart({
					variables: {
						shopId: order.shop.pk,
						token: order.token,
					}
				}).then((reRes) => {
					openCart(order.shop.pk, reRes.data.orderRecart.token)
					history.goBack()
				}).catch(e => gqlErrorHandler(e))
			},
			onCancel() { },
		});
	}
	const hanbleClose = (e) => {
		e.preventDefault()
		$('#layerOrderDetail_background').animate({ opacity: 0 }, 'fast')
		$('#layerOrderDetail').animate({ opacity: 0 }, 'fast', history.goBack)
	}

	const style1 = {
		opacity: 0,
		visibility: 'visible',
		backgroundColor: 'black',
		position: 'fixed',
		inset: '0px',
		zIndex: 90,
		cursor: 'pointer'
	}
	const style2 = {
		touchAction: 'none',
		visibility: 'visible',
		position: 'fixed',
		overflow: 'auto',
		zIndex: 91,
		width: '100%',
		height: '100%',
		top: '0px',
		left: '0px',
		textAlign: 'center'
	}
	const style3 = {
		opacity: 0,
		visibility: 'visible',
		pointerEvents: 'auto',
		display: 'block',
		outline: 'none',
		textAlign: 'left',
		position: 'relative',
		verticalAlign: 'middle'
	}
	// ID값들(layerOrderDetail_background, layerOrderDetail_wrapper) 은 jqeury의 animate외 용도 없음.
	return (
		<>
			<div id="layerOrderDetail_background" style={style1}></div>
			<div id="layerOrderDetail_wrapper" style={style2}>
				{typeof order === "undefined"
					? <LoadingCenter />
					: <div id="layerOrderDetail" style={style3} className="popup_content" >
						<div className="layerBox">
							<div className="layerHd">
								<a href="#this" className="btnReOrder" onClick={(e) => handleRecart(order, e)}>재주문</a>
								<h3>주문상세</h3>
							</div>
							<div className="layerCnt">
								<div className="orderDetailView">
									<div className="section">
										<dl className="nameDate">
											<dt>{order.shop.name}</dt>
											<dd>{dayjs(order.orderAt, 'YYYY.MM.DD HH:mm').format('YYYY-MM-DD HH:mm')}</dd>
										</dl>
										<div className="group1">
											<ul>
												{order.items.map(item =>
													<li key={item.pk} className={item.isCancel ? 'cancle' : ''}>
														<dl>
															<dt>[{item.receipt.subshopName}] {item.receipt.name} {item.count > 1 && (' X ' + item.count)}</dt>
															<dd>{formatter.format(item.receipt.price * item.count)} 원</dd>
														</dl>
													</li>
												)}
											</ul>
										</div>
										<div className="group2">
											<ul>
												<li>
													<dl>
														<dt>주문금액</dt>
														<dd>{formatter.format(_.sumBy(order.items, 'price'))} 원</dd>
													</dl>
												</li>
												<li>
													<dl>
														<dt>배달금액</dt>
														<dd>+ {formatter.format(order.priceShip)} 원</dd>
													</dl>
												</li>
												{order.coupon &&
													<li>
														<dl>
															<dt>쿠폰 할인</dt>
															<dd>- {formatter.format(order.priceDiscount)} 원</dd>
														</dl>
													</li>
												}
											</ul>
										</div>
										<div className="group3">
											<ul>
												<li>
													<dl>
														<dt>총 결제금액</dt>
														<dd>{formatter.format(order.price)} 원</dd>
													</dl>
												</li>
												<li>
													<dl>
														<dt>결제수단</dt>
														<dd>{order.payinfo.payMethodName}</dd>
													</dl>
												</li>
											</ul>
										</div>
									</div>
									<hr className="hrBk" />
									<div className="section">
										{order.commentShop &&
											<div className="group4">
												<h4>매장 요청사항</h4>
												<p style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
													{order.commentShop}
												</p>
											</div>
										}
										<div className="group4">
											<h4>배달 요청사항</h4>
											<p style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
												{order.commentShip}
											</p>
										</div>
										<div className="group4">
											<h4>배달주소</h4>
											<p>
												{order.shipAddr1} {order.shipAddr2}
											</p>
										</div>
										<div className="group4">
											<h4>수령인 전화번호</h4>
											<p>
												{order.phone.replace(/^(.*)(.{4})(.{4})$/, '$1-$2-$3')}
											</p>
										</div>
										<div className="group4">
											<h4>시장정보</h4>
											<p>
												주소: {order.shop.addr1} {order.shop.addr2}<br />
												전화번호: {order.shop.tel?.replace(/^(.*)(.{4})(.{4})$/, '$1-$2-$3')}
											</p>
										</div>
										{order.orderStatus === 'CANCEL' &&
											<div className="group4">
												<h4>취소사유</h4>
												<p style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
													{order.cancelReason}
												</p>
											</div>
										}
									</div>
								</div>
							</div>
							<a href="#this" className="txtHide btnLayerClose1" onClick={hanbleClose}>닫기</a>
						</div>
					</div>
				}
			</div>
		</>
	)
}