import React from "react"
import { LoadingCenter } from './Loading'
import { Link, useHistory } from "react-router-dom"
import { useMutation, useQuery } from '@apollo/client'
import { gqlErrorHandler, openMarket, openCart } from './Library'
import Modal from './CustomModal'
import _ from 'lodash'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { loader } from 'graphql.macro'

dayjs.extend(customParseFormat)

const orderListGql = loader('../gql/order_list.gql')
const cartRemoveGql = loader('../gql/cart_remove.gql')
const cartRemoveAllGql = loader('../gql/cart_remove_all.gql')
const cartItemCountGql = loader('../gql/cart_item_count.gql')
const formatter = new Intl.NumberFormat()

/* status가 CART인 gongOrders의 node당 하나의 장바구니임, 시장하나당 하나의 활성화된 장바구니가 있을 수 있음.*/
export default function () {
    const history = useHistory()

    const [cartRemove] = useMutation(cartRemoveGql)
    const [cartRemoveAll] = useMutation(cartRemoveAllGql)
    const [cartItemCount] = useMutation(cartItemCountGql)

    const values = {
        gid: process.env.REACT_APP_SERVICE_GID,
        status: "CART",
        withItemsGoods: true,
    }
    const { error, data, refetch } = useQuery(orderListGql, { variables: values, fetchPolicy: 'network-only'/*'cache-and-network'*/ });
    if (error) { gqlErrorHandler(error); return null; }

    const handleCartItemCount = async (e, shopId, cartitemId, count, ctoken) => {
        e.preventDefault()
        if (count < 1) return
        try {
            const res = await cartItemCount({ variables: { shopId, cartitemId, count, ctoken } })

            if (res.data.orderCartCount.result === false) {
                Modal.alert({ content: res.data.orderCartCount.msg })
                return
            }
            refetch()
        } catch (e) { gqlErrorHandler(e) }

    }
    const handleCartRemove = async (e, shopId, cartitemId) => {
        e.preventDefault()
        try {
            await cartRemove({ variables: { shopId, cartitemId } })
            refetch()
        } catch (e) { gqlErrorHandler(e) }
    }
    const handleCartRemoveAll = async (e) => {
        e.preventDefault()
        try {
            await cartRemoveAll()
            refetch()
        } catch (e) { gqlErrorHandler(e) }
    }

    return (
        <div id="wrap">
            <div id="header" className="subHeader1">
                <div className="header">
                    <div className="btnList"><a href="#this" onClick={e => { e.preventDefault(); history.goBack() }} className="txtHide">뒤로가기</a></div>
                    <h1>담은 상품 모아보기</h1>
                    <a href="#this" onClick={handleCartRemoveAll} className="btnAllDel">전체삭제</a>
                </div>
            </div>
            <div id="container">
                <div id="contents">
                    <div className="basketList type02">
                        {!data && <LoadingCenter />}
                        {data?.gongOrders.edges.map(item => {
                            return (
                                <div key={`${item.node.shop.pk}${item.node.pk}`} className="basketGp">
                                    <h3>{item.node.shop.name}</h3>
                                    <ul>
                                        {item.node.items.map(menu => {
                                            const unBuying = !menu.goods || !menu.goods.isOpen || menu.goods.soldout ? 'unBuying' : null
                                            return (
                                                <li key={menu.pk} className={unBuying}>
                                                    <p className="txtMenu wordType02">[{menu.receipt.subshopName}] {menu.receipt.name}</p>
                                                    <ul>
                                                        <li>가격 : {formatter.format(menu.receipt.price)}원</li>
                                                    </ul>
                                                    <div className="qtyDiv">
                                                        <h4>{unBuying === null ? `${formatter.format(menu.price)}원` : '구매불가'}</h4>
                                                        <div className="qtySeting">
                                                            <a href="#this" onClick={e => handleCartItemCount(e, item.node.shop.pk, menu.pk, menu.count - 1, item.node.token)} className="btnMinus txtHide">감소</a>
                                                            <input type="text" value={menu.count} readOnly />
                                                            <a href="#this" onClick={e => handleCartItemCount(e, item.node.shop.pk, menu.pk, menu.count + 1, item.node.token)} className="btnPlus txtHide">증가</a>
                                                        </div>
                                                    </div>
                                                    <a href="#this" onClick={e => handleCartRemove(e, item.node.shop.pk, menu.pk)} className="btnDel txtHide">삭제</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <div className="totalPrice">
                                        <dl className="total">
                                            <dt>총 상품금액</dt>
                                            <dd>{formatter.format(_.sumBy(item.node.items, i => !i.goods || !i.goods.isOpen || i.goods.soldout ? 0 : i.price))}원</dd>
                                        </dl>
                                        <dl>
                                            <dt>배송비</dt>
                                            <dd>{formatter.format(item.node.priceShip)} 원</dd>
                                        </dl>
                                        <dl>
                                            <dt>최소 주문 금액</dt>
                                            <dd>{formatter.format(item.node.shop.deliveryMinPrice)} 원</dd>
                                        </dl>
                                    </div>
                                    <div className="btns">
                                        <a href="#this" onClick={e => openMarket(item.node.shop.pk, e)} className="btnTy3">상품 추가</a>
                                        <a href="#this" onClick={e => openCart(item.node.shop.pk, item.node.token, e)} className="btnTy3 on blue">장바구니</a>
                                    </div>
                                </div>
                            )
                        })}
                        {data?.gongOrders.edges.length < 1 &&
                            <div className="basketNoData">
                                <p>
                                    장바구니에 담긴 상품이 없습니다.
                                    <span><Link to="/shop" className="btnBuyGo">장보러 가기</Link></span>
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}