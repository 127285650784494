import React from "react";
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

export default function () {
    return <Spin tip="" indicator={antIcon} />
}

export function LoadingCenter(props) {
    /* 기본 props 주고, 외부props(stype포함)와 머지 */
    let myProps = {
        tip: '',
        indicator: antIcon,
        ...props,
        style: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 80,
            ...props.style
        }
    }

    return <Spin {...myProps} />
}