import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import Popup from './Popup';

class TermsList extends React.Component {
	render() {
		return (
			<div id="wrap">
				<div id="header" className="subHeader1">
					<div className="header">
						<div className="btnList">
							<NavLink to="/mypage" className="txtHide">뒤로가기</NavLink>
						</div>
						<h1>제공 서비스별 약관 </h1>
					</div>
				</div>
				<div id="container">
					<div id="contents">
						<div className="mpListMenu">
							<ul>
								<li><Popup href="https://smallbiz.notion.site/_-b619bc9024644610b3e49430737e0f93">통합회원 이용약관</Popup></li>
								<li><Popup href="https://smallbiz.notion.site/_-e3e51ed410f2462eb7ef4775a2e19cbb">서비스 이용약관</Popup></li>
								<li><Popup href="https://smallbiz.notion.site/_-507cc1d30456453f9cc29a424b99ae6e">개인정보처리방침</Popup></li>
								<li><Popup href="https://smallbiz.notion.site/_-047690bec15942eabfe65eb55b639211">위치기반 서비스 이용약관</Popup></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(TermsList)