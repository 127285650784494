import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, useRouteMatch, NavLink } from 'react-router-dom';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { loader } from 'graphql.macro';
import { withApolloClient } from './ApolloClient';
import $ from 'jquery';
import { gqlErrorHandler } from './Library';
import { useQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import dateFormat from 'dateformat';
import { chkImageUrl } from './Library';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const NOTICES_QUERY = loader('../gql/notice_list.gql');
const NOTICE_QUERY = loader('../gql/notice.gql');
const categoryClass = {
	EVENT: 'type1',
	NOTICE: 'type4',
	UPDATE: 'type5',
};

const Notice = (props) => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);
	let match = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${match.path}`} component={NoticeList} />
			<Route path={`${match.path}/view/:id`} component={NoticeView} />
		</Switch>
	)
}

function NoticeList(props) {
	const [category, setCategory] = useState("");
	const [title, setTitle] = useState("");
	const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />
	const doFetchMore = useRef(true);

	useEffect(() => {
		window.scrollTo(0, 0);
		$(".tabSectoin").css({ display: 'none', marginLeft: 30, opacity: 0 });
		$(".tabSectoin").show().stop().animate({ 'margin-left': 0, opacity: 1 }, 500);
	}, [category]);

	const searchVar = {
		gongId: process.env.REACT_APP_SERVICE_GID,
		category: category,
		title: title
	};

	const handleFetchMore = (fetchMore, data) => {
		if (!data || data.gongNotices.pageInfo.hasNextPage === false) return
		if (doFetchMore === false) return
		doFetchMore.current = false;

		searchVar.cursor = data.gongNotices.pageInfo.endCursor;
		fetchMore({
			variables: searchVar,
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongNotices.edges;
				const pageInfo = fetchMoreResult.gongNotices.pageInfo;
				return newEdges.length
					? {
						gongNotices: {
							__typename: previousResult.gongNotices.__typename,
							edges: [...previousResult.gongNotices.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		}).then(() => doFetchMore.current = true);
	}

	const messageTimeHandler = (createdAt) => {
		let createdAt_format = createdAt.replace(/-/g, '/');
		let create_timestamp = new Date(createdAt_format).getTime();
		let now_timestamp = new Date().getTime();
		let threedays = (60 * 60 * 24 * 3);
		let time_diff = parseInt(now_timestamp / 1000) - parseInt(create_timestamp / 1000);

		if (time_diff < threedays) {
			return (<em className="txtHide icoNew">New</em>);
		}
		return "";
	}

	const { error, data, fetchMore } = useQuery(NOTICES_QUERY, { variables: searchVar, fetchPolicy: 'network-only' });
	//useBottomScrollListener(() => handleFetchMore(fetchMore, data));
	if (error) { gqlErrorHandler(error); return null; }

	return (
		<div id="wrap">
			<div id="header" className="subHeader1">
				<div className="header">
					<div className="btnList">
						<NavLink to="/mypage" className="txtHide">뒤로가기 </NavLink>
					</div>
					<h1>공지사항</h1>
				</div>
			</div>
			<div id="container">
				<div id="contents">
					<div className="comSch1">
						<div className="sch">
							<input type="search" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="검색어를 입력해주세요" />
							<a href="#this" className="txtHide btnSch">검색</a>
						</div>
					</div>
					<div className="tabSt2">
						<ul>
							<li><button className={category === "" ? "on" : ""} data-conts="all" onClick={(e) => setCategory('')}>전체</button></li>
							<li><button className={category === "EVENT" ? "on" : ""} data-conts="event" onClick={(e) => setCategory('EVENT')}>이벤트</button></li>
							<li><button className={category === "NOTICE" ? "on" : ""} data-conts="notice" onClick={(e) => setCategory('NOTICE')}>공지</button></li>
							<li><button className={category === "UPDATE" ? "on" : ""} data-conts="update" onClick={(e) => setCategory('UPDATE')}>업데이트</button></li>
						</ul>
					</div>
					<div className="tabSectoin on">
						<div className="noticeAlarm list">
							<ul>
								{
									data?.gongNotices.edges.length > 0
										? < BottomScrollListener onBottom={() => handleFetchMore(fetchMore, data)} triggerOnNoScroll={false} debounceOptions={{ leading: false }} debounce={500}>
											{data?.gongNotices.edges.map((v, k) => (
												<li key={k}>
													<a href={"/mypage/notice/view/" + v.node.pk} className="alTit">
														<h4 className={`typeSym ${categoryClass[v.node.category]}`}>{v.node.categoryName}</h4>
														<p>
															{v.node.title}
															{messageTimeHandler(v.node.createdAt)}
														</p>
														<span>{v.node.createdAt}</span>
													</a>
												</li>
											))}
										</BottomScrollListener> :
										<li className="noData">
											<p>{title.trim() === "" ? <>작성된 공지사항이 없습니다.</> : <>‘{title}’에 대한 검색 결과가 없습니다.</>}</p>
										</li>

								}
							</ul>
							{data?.gongNotices.pageInfo.hasNextPage === true &&
								<div style={{ paddingLeft: '48%', marginTop: 5 }}>
									<Spin tip="" indicator={antIcon} />
								</div>
							}
						</div>
					</div >
				</div >
			</div>
		</div >
	)
}
class NoticeView extends React.Component {
	render() {
		const { id } = this.props.match.params;
		return (
			<div id="wrap">
				<div id="header" className="subHeader1">
					<div className="header">
						<div className="btnList">
							<NavLink to="/mypage/notice" className="txtHide">뒤로가기 </NavLink>
						</div>
						<h1>공지사항</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents">
						<Query query={NOTICE_QUERY} variables={{ pk: id }}>
							{({ loading, error, data }) => {
								if (loading) return null
								if (error) { gqlErrorHandler(error); return null }
								if (data.gongNotice === '') {
									return <></>
								}
								return (
									<div className="noticeView">
										<div className="thDiv">
											<h4 className={`typeSym ${categoryClass[data.gongNotice.category]}`}>{data.gongNotice.categoryName}</h4>
											<p>{data.gongNotice.title}</p>
											<span>{dateFormat(data.gongNotice.createdAt, "yyyy-mm-dd")}</span>
										</div>
										{
											data.gongNotice.imgUrl &&
											<div className="tdDiv">
												<div className="img">
													<img src={chkImageUrl(data.gongNotice.imgUrl)} alt="" />
												</div>
											</div>
										}
										<div className="tdDiv" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: data.gongNotice.content }}></div>
									</div>
								)
							}}
						</Query>

					</div>
				</div>
			</div>
		)
	}

}

export default withApolloClient(Notice);