import React, { useRef } from "react";
import { useQuery } from '@apollo/client';
import useShopListParam from '../hook/useShopListParam'
import { loader } from 'graphql.macro'
import { gqlErrorHandler, openMarket, chkImageUrl } from './Library'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { LoadingCenter } from './Loading'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const shopListGql = loader('../gql/shop_list.gql')
const formatter = new Intl.NumberFormat()

function ShopList(props) {
	const doFetchMore = useRef(true)
	const values = useShopListParam(props)
	const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

	const handleFetchMore = async (fm, data) => {
		if (!data || data.gongShops.pageInfo.hasNextPage === false) return
		if (doFetchMore.current === false) return

		doFetchMore.current = false

		await fm({
			variables: {
				cursor: data.gongShops.pageInfo.endCursor
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongShops.edges;
				const pageInfo = fetchMoreResult.gongShops.pageInfo;

				return newEdges.length
					? {
						gongShops: {
							__typename: previousResult.gongShops.__typename,
							edges: [...previousResult.gongShops.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		})

		doFetchMore.current = true
	}

	const { loading, error, data, fetchMore } = useQuery(shopListGql, { variables: values });
	useBottomScrollListener(() => handleFetchMore(fetchMore, data))

	if (loading) return <LoadingCenter />
	if (error) { gqlErrorHandler(error); return null }

	return (
		<div className="marketList">
			<ul>
				{data.gongShops.edges.map((item) => (
					<li key={item.node.shop.pk}>
						<a href="#this" onClick={(e) => openMarket(item.node.shop.pk, e)}>
							<div className={`img ${item.node.shop.isOpened ? '' : 'close'}`}>
								{chkImageUrl(item.node.shop.imgSize) && <img src={chkImageUrl(item.node.shop.imgSize)} alt="" />}
								<div className="location">{item.node.shop.addr1}</div>
							</div>
						</a>
						<p>
							{item.node.shop.name}
							<span>{item.node.m < 1000 ? item.node.m + 'm' : formatter.format((item.node.m / 1000).toFixed(1)) + 'km'}</span>
						</p>
						<dl>
							<dt>운영시간</dt>
							<dd>{`${item.node.shop.time[0]} - ${item.node.shop.time[1]}`}</dd>
						</dl>
						<dl>
							<dt>배달안내</dt>
							<dd>{item.node.shop.shipping}</dd>
						</dl>
					</li>
				))}
				{data.gongShops.edges.length < 1 &&
					<li className="noData">
						<p>현재 고객님이 설정한 위치 기준으로<br />노출되는 매장이 없습니다.</p>
					</li>
				}
			</ul>
			{data.gongShops.pageInfo.hasNextPage === true &&
				<div style={{ paddingLeft: '48%', marginTop: 5 }}>
					<Spin tip="" indicator={antIcon} />
				</div>
			}
		</div>
	)
}

export default ShopList