import React, { useEffect, useState, useMemo } from "react"
import { Link, useHistory } from 'react-router-dom'
import _ from "lodash"
import $ from 'jquery'
import { useQuery, useReactiveVar } from '@apollo/client'
import { loader } from 'graphql.macro'
import { locRecentGoodsVar } from './ApolloClient'
import { gqlErrorHandler, chkImageUrl, isLogin, headerShadow, openMarket, openGoods } from './Library'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import CartFloat, { CartAdd } from './CartIcon'
import ZzimIcon from './ZzimIcon'
import Popup from './Popup'
import Footer from './Footer'
import PushMark from './PushMark'
import useAddrInfo from '../hook/useAddrInfo'

const mainBannerGql = loader('../gql/main_banner.gql')
const shopListGql = loader('../gql/shop_list.gql')
const lastOrderGoodsGql = loader('../gql/last_order_goods.gql')
const recentGoodsListGql = loader('../gql/recent_goods_list.gql')

const formatter = new Intl.NumberFormat()

function Main() {
	useEffect(() => {
		window.scrollTo(0, 0)
	})
	/* global AppScheme */
	typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://clear_history`)

	return (
		<div id="wrap">
			<Header />
			<div id="container">
				<div id="contents">
					<div className="mainSection">
						<Banner sa={<SearchArea />} />
						<NearbyMarket />
						{isLogin() &&
							<>
								<RecentGoods />
								<LastOrder />
							</>
						}
						<CartFloat />
					</div>
				</div>
			</div>
			<Copyright />
			<Footer />
		</div>
	)
}
function Header() {
	const { headerAddrTxt } = useAddrInfo()
	useEffect(() => {
		setTimeout(function () {
			$('.layerMyAdr').fadeOut(1000)
		}, 3000)
		headerShadow()
	})
	return (
		<div id="header">
			<div className="header">
				<div className="adr"><Link to='/mypage/set-address'>{headerAddrTxt}</Link></div>
				<div className="layerMyAdr">
					<p>현재 이 주소가 맞나요?</p>
				</div>
				<div className="btnHr">
					<PushMark />
				</div>
			</div>
		</div>
	)
}
const Banner = (props) => {
	const values = { gid: process.env.REACT_APP_SERVICE_GID, }
	const { loading, error, data } = useQuery(mainBannerGql, { variables: values })

	useEffect(() => {
		if (!loading) {
			new Swiper('.mainVisual .swiper-container', {
				slidesPerView: 'auto',
				loop: true,
				autoplay: {
					delay: 3500,
					disableOnInteraction: false
				},
				pagination: {
					el: ".mainVisual .swiper-pagination",
					type: "fraction",
				}
			})
		}
	})

	if (loading) return <div style={{ height: 286 }}></div>
	if (error) { gqlErrorHandler(error); return null; }

	return (
		<>
			<div className="mainVisual">
				<div className="swiper swiper-container">
					<ul className="swiper-wrapper">
						{data.gongBanners.edges.map((item) => (
							<li className="swiper-slide" key={item.node.id}><Popup href={item.node.url} type={item.node.target}><img src={chkImageUrl(item.node.imgUrl)} alt="이미지" /></Popup></li>
						))}
					</ul>
				</div>
				<Link to='/event' className="swiper-pagination" />
			</div>
			{props.sa}
		</>
	)
}

function SearchArea() {
	const history = useHistory()

	const [sticky, setSticky] = useState(false)
	const throttledScroll = useMemo(() => _.throttle(() => {
		const comparison_height = $('.mainVisual').height() - 24 //swipe 이미지 높이 - 검색창 margin-top				
		window.scrollY >= comparison_height ? setSticky(true) : setSticky(false)
	}, 10), [])

	useEffect(() => {
		window.addEventListener('scroll', throttledScroll)
		return () => {
			window.removeEventListener('scroll', throttledScroll)
		}
	}, [throttledScroll])

	return (
		<div id="hdSticker-sticky-wrapper" className={`sticky-wrapper ${sticky ? 'is-sticky' : ''}`} style={sticky ? { height: 69 } : { height: 55 }}>
			<div className="mainSch" id="hdSticker" style={sticky ? { position: 'fixed', top: 60, margin: 0, borderTop: '1px solid #e7e7e7', background: '#fff' } : { position: 'relative', margin: '-24px 0 0' }}>
				<div className="schSec">
					<div className="input" onClick={(e) => { e.preventDefault(); history.push('/search') }}>
						<input type="text" placeholder="무엇을 찾으세요?" />
						<a href="#this" className="txtHide btnAdrSch">검색</a>
					</div>
				</div>
			</div>
		</div>
	)
}

function NearbyMarket() {
	const [on, setOn] = useState(0)
	const { geo, addrCode } = useAddrInfo()
	let values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		km: 20,
		orderType: 'delivery',
		sort: 'near',
		isOpen: true,
		geo,
		addrCode
	}

	const { loading, error, data } = useQuery(shopListGql, { variables: values })

	useEffect(() => {
		if (!loading) {
			new Swiper('.deliveryOk .swiper-container', {
				slidesPerView: 'auto'
			})
		}
	}, [loading])

	useEffect(() => {
		$('.layerMarketInfo').animate({ opacity: "1" }, 500)
	})

	if (loading) return <div style={{ height: 565 }}></div>
	if (error) { gqlErrorHandler(error); return null; }

	return (
		<>
			<h3>{data.gongShops.edges.length < 1 ? <>근처 배달 가능 시장</> : <Link to='/shop'>근처 배달 가능 시장</Link>}</h3>
			<div className="deliveryOk">
				<div className="swiper swiper-container">
					<ul className="swiper-wrapper">
						{data.gongShops.edges.map((item, idx) => (
							<li className={`swiper-slide ${on === idx ? 'on' : ''}`} key={`new${item.node.shop.pk}`} onClick={() => setOn(idx)}>
								<div className="img">
									{chkImageUrl(item.node.shop.img) && <img src={chkImageUrl(item.node.shop.img)} alt="" />}
								</div>
								<p>{item.node.shop.name}</p>
							</li>
						))}
						{data.gongShops.edges.length < 1 &&
							<li className="noData">
								<p>근처 배달 가능한 시장이 없어요.</p>
							</li>
						}
					</ul>
				</div>
				{data.gongShops.edges[on] &&
					<div className="layerMarketInfo" key={on} style={{ opacity: 0 }}>
						<div className="box">
							<ul>
								<li><strong>영업시간 :</strong> {data.gongShops.edges[on].node.shop.time[0]} - {data.gongShops.edges[on].node.shop.time[1]}</li>
								<li><strong>배달안내 :</strong> {data.gongShops.edges[on].node.shop.shipping}</li>
							</ul>
							<a href="#this" className="btnGo" onClick={(e) => openMarket(data.gongShops.edges[on].node.shop.pk, e)}>장보기</a>
						</div>
					</div>
				}
			</div>
		</>
	)
}

function RecentGoods() {
	/* goodsList의 pks가 필수가 아님, 빈값을 때 전체메뉴를 가져오네...그래서 아래 recentGoodsListGql에 skip을 꼭 지정해야함. */
	const skip = !(useReactiveVar(locRecentGoodsVar)?.length > 0)
	const { error, data } = useQuery(recentGoodsListGql, { variables: { first: 4 }, fetchPolicy: 'cache-and-network', skip })

	if (!skip && !data) return <div style={{ height: 726 }}></div> //skip이 true일 때는 data가 undefined이므로, skip이 false일 때만 공간차지하도록.
	if (error) { gqlErrorHandler(error); return null }

	return (
		<>
			<hr className="hrBk" />
			<h3>최근 본 상품</h3>
			<div className="bookmarkList type2">
				<div className="lists">
					<ul>
						{data?.goodsList.edges.map((item) => {
							let dimClassName = ''
							dimClassName = !item.node.isOpen ? 'prepare' : dimClassName
							dimClassName = item.node.soldout ? 'soldOut' : dimClassName
							const allowClick = dimClassName === ''

							return (
								<li key={item.node.pk + 'rg'} className={dimClassName}>
									<a href="#this" onClick={(e) => { e.preventDefault(); allowClick && openGoods(item.node.shopinfo.pk, item.node.pk, e) }}>
										<div className="img">
											{chkImageUrl(item.node.imageMain) && <img src={chkImageUrl(item.node.imageMain)} alt="" />}
										</div>
									</a>
									<div className="cnt">
										<div className="likeBasket" style={{ position: 'relative' }}>
											<ZzimIcon {...{ goods: item.node }} />
											<CartAdd goods={item.node} />{/* dim 시 클릭안되는건 css에서 처리한거임... */}
										</div>
										<dl>
											<dt>
												<a href="#this" onClick={(e) => { e.preventDefault(); allowClick && openGoods(item.node.shopinfo.pk, item.node.pk, e) }}>
													{item.node.name}
												</a>
											</dt>
											<dd><a href="#this" onClick={(e) => e.preventDefault()}>{item.node.shopinfo.name} [{item.node.subShop?.name}]</a></dd>
										</dl>
										<p>{formatter.format(item.node.price)}원</p>
									</div>
								</li>
							)
						})}
						{!(data?.goodsList.edges.length > 0) &&
							<li className="noData">
								<p>최근 본 상품이 없어요.</p>
							</li>
						}
					</ul>
				</div>
				<hr className="hrBk" />
			</div>
		</>
	)
}
const LastOrder = () => {
	const values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		first: 4,
		status: 'FINISH'
	}
	const { error, data } = useQuery(lastOrderGoodsGql, { variables: values, fetchPolicy: 'cache-and-network' })

	if (!data) return <div style={{ height: 744 }}></div> //cache-and-network일때는 loading보다 data로 판단.
	if (error) { gqlErrorHandler(error); return null; }

	return (
		<>
			<h3>최근 구매한 상품</h3>
			<div className="bookmarkList type3">
				<div className="lists">
					<ul>
						{data.gongOrderGoods.edges.map((item, idx) => {
							let dimClassName = ''
							dimClassName = !item.node.isOpen ? 'prepare' : dimClassName
							dimClassName = item.node.soldout ? 'soldOut' : dimClassName
							const allowClick = dimClassName === ''

							return (
								<li key={item.node.pk + 'ro' + idx} className={dimClassName}>
									<div className="img" onClick={(e) => allowClick && openGoods(item.node.shopinfo.pk, item.node.pk, e)}>
										{chkImageUrl(item.node.imageMain) && <img src={chkImageUrl(item.node.imageMain)} alt="" />}
									</div>
									<div className="cnt">
										<div className="likeBasket">
											<ZzimIcon {...{ goods: item.node }} />
											<CartAdd goods={item.node} />{/* dim 시 클릭안되는건 css에서 처리한거임... */}
										</div>
										<dl>
											<dt onClick={(e) => allowClick && openGoods(item.node.shopinfo.pk, item.node.pk, e)}>{item.node.name}</dt>
											<dd>{item.node.shopinfo.name} [{item.node.subShop?.name}]</dd>
										</dl>
										<p>{formatter.format(item.node.price)}원</p>
									</div>
								</li>
							)
						})}
						{data.gongOrderGoods.edges.length < 1 &&
							<li className="noData">
								<p>최근 구매한 상품이 없어요.</p>
							</li>
						}
					</ul>
				</div>
			</div>
		</>
	)
}

function Copyright() {
	useEffect(() => {
		$('.btnCmpInfo').off('click').on('click', function (e) {
			e.preventDefault()
			var _this = $('.btnCmpInfo')
			if (_this.hasClass('on')) {
				$(this).removeClass('on')
				$('.offDiv').show()
				$('.onDiv').hide()
			} else {
				$(this).addClass('on')
				$('.offDiv').hide()
				$('.onDiv').show()
				$('#wrap').scrollTop($('#wrap').scrollTop() + 54)
			}
		})
	})
	return (
		<div id="footer">
			<div className="footer">
				<a href="#this" className="btnCmpInfo">사업자 정보 확인</a>
				<div className="offDiv">
					<p className="cmpDesc">
						㈜커넥트웨이브는 통신판매중개자이며 통신판매의 당사자가 아닙니다.<br />
						따라서 (주)커넥트웨이브는 상품거래정보 및<br />
						거래에 대한 책임을 지지 않습니다.
					</p>
					<a href="tel:1533-2002" className="btnTel">가보장 고객센터 : 1533-2002</a>
				</div>
				<div className="onDiv">
					<ul>
						<li>㈜커넥트웨이브</li>
						<li>대표 : 김기록</li>
						<li>사업자 번호 : 117-81-40065</li>
						<li>통신판매업신고 번호: 제 <strong>2004-서울양천-00918</strong>호</li>
						<li>주소 : 서울특별시 양천구 목동동로 233-1, 5층 501호(목동, 현대드림타워)</li>
					</ul>
					<div className="guideTerm">
						<Popup href="https://smallbiz.notion.site/_-e3e51ed410f2462eb7ef4775a2e19cbb">이용약관</Popup>
						<Popup href="https://smallbiz.notion.site/_-507cc1d30456453f9cc29a424b99ae6e">개인정보처리방침</Popup>
						<Popup href="https://smallbiz.notion.site/_-047690bec15942eabfe65eb55b639211">위치기반 서비스 이용약관</Popup>
					</div>
					<p className="cmpDesc">
						㈜커넥트웨이브는 통신판매중개자이며 통신판매의 당사자가 아닙니다.<br />
						따라서 커넥트웨이브는 상품거래정보 및 거래에 대한 책임을 지지 않습니다.
					</p>
					<a href="tel:1533-2002" className="btnTel">가보장 고객센터 : 1533-2002</a>
				</div>
			</div>
		</div>
	)
}

export default Main
