import React from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { gql } from 'graphql.macro'
import { isLogin } from './Library'

// order_list.gql에 totalCount를 넣으면 페이징에서 에러남, 여기서 단독처리.
const orderListGql = gql`
	query getOrderCount($gid: Float!, $status: String, $day: Float = 180) { 
		gongOrders(gid: $gid, status: $status, day: $day) {
			totalCount
		}
	}
`

const Footer = () => {
	const values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		status: "PAY,WORKING,SHIPPING" //주문전체상태: PAY,WORKING,SHIPPING,FINISH,CANCEL
	}

	if (!isLogin() || !localStorage.getItem('liveOrderCnt')) localStorage.setItem('liveOrderCnt', 0)
	let liveOrderCnt = localStorage.getItem('liveOrderCnt')

	// 기존 client.query를 useQuery로 개선, 문제없을 시 기타지방도적용예정.
	const { data } = useQuery(orderListGql, { variables: values, fetchPolicy: 'network-only', skip: !isLogin() })

	if (data?.gongOrders?.totalCount) {
		liveOrderCnt = data.gongOrders.totalCount
		localStorage.setItem('liveOrderCnt', liveOrderCnt)
	}

	return (
		<nav className="tabBar">
			<NavLink to="/" exact activeClassName="active" className="txtHide btnHome">홈</NavLink>
			<ul>
				<li><NavLink to="/shop" activeClassName="active"><span>시장보기</span></NavLink></li>
				<li><NavLink to="/order" activeClassName="active"><span>주문내역</span><em className="num">{liveOrderCnt}</em></NavLink></li>
				<li></li>
				<li><NavLink to="/mypage/bookmark" activeClassName="active"><span>찜</span></NavLink></li>
				<li><NavLink to="/mypage" activeClassName="active"><span>마이페이지</span></NavLink></li>
			</ul>
		</nav>
	)
}

export default Footer