import React from "react";
import InputDelButton from "./InputDelButton";
import { withRouter, Link, Route } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import Modal from './CustomModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AgreeTerms from "./AgreeTerms";
import { withApolloClient } from './ApolloClient';
import { gql, loader } from 'graphql.macro';
import { gqlErrorHandler, saveLoginMember } from './Library';
import Timer from './Timer';

const memberCheckGql = gql`
	query memberCheck($gid: Int) {
		gongMemberCheck(gongId: $gid) 
	}
`
const SET_SHOP_PUSH_MUTATION_GQL = loader('../gql/set_push_shop_setting.gql');
const PUSH_SETTING_GQL = loader('../gql/push_setting.gql');

export default withApolloClient(withRouter(class MobileConfirm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			authCode: '',
			values: { mobile: '', confirmNo: '', sendedMobile: '' },
			member: { id: '', nick: '', email: '', mobile: '' },
			toAgree: false,
			msgTimeout: false,
			msgSendDate: null
		}

		let code = new URLSearchParams(window.location.search).get("code");
		if (code && localStorage.confirmed_mobile) {
			const REDIRECT_URI = encodeURIComponent(process.env.REACT_APP_SERVICE_URI + window.location.pathname);
			const authStr = 'Basic ' + btoa(localStorage.client_id + ':' + localStorage.client_secret);

			// 인증을 거치고 온경우
			axios.post(`${process.env.REACT_APP_SERVER_REST}/v2/oauth/member/token`,
				`grant_type=authorization_code&code=${code}&redirect_uri=${REDIRECT_URI}`,
				{
					headers: { 'Authorization': authStr }
				})
				.then(res => {
					if (res.data.access_token && res.data.refresh_token) {
						localStorage.access_token = res.data.access_token;
						localStorage.refresh_token = res.data.refresh_token;
						localStorage.token_expires = Math.round(new Date().getTime() / 1000) + parseInt(res.data.expires_in);

						// 회원가입인경우
						if (this.props.match.params.mode === 'join') {
							if (localStorage.confirmed_email) {
								localStorage.access_token = '';
								localStorage.refresh_token = '';
								localStorage.token_expires = '';
								Modal.info({
									content: '이미 가입된 휴대폰번호입니다.',
									onOk: () => { this.props.history.replace('/login'); }
								});
							} else {
								// 회원가입페이지로 이동
								this.props.history.push('/join');
							}

							// 휴대폰번호로 로그인인경우
						} else {
							// 이메일등록인경우 메인페이지로
							if (localStorage.confirmed_email) {
								// MemberInfo
								axios.get(`${process.env.REACT_APP_SERVER_REST}/members/info`,
									{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
									.then(res => {
										if (res.data.result) {
											// 해당 앱의 약관동의를 거친지 확인
											this.props.client.query({
												query: memberCheckGql,
												variables: { gid: process.env.REACT_APP_SERVICE_GID },
												fetchPolicy: 'network-only',
											}).then((data) => {
												if (data.data.gongMemberCheck) {
													// 로그인처리후 replace
													saveLoginMember(res.data.id, res.data.nick, res.data.email, res.data.phone);

													// 회원이지만 공공앱 처음 로그인 시
												} else {
													// 약관 동의후 로컬스토리지 저장을 위해 회원정보 임시저장
													let member = {
														id: res.data.id,
														nick: res.data.nick,
														email: res.data.email,
														mobile: res.data.phone
													}
													this.setState({ toAgree: true, member: member });
												}

											}).catch(() => { Modal.error({ content: '앱정보 처리중 오류가 발생하였습니다.' }); });
										}
									})
									.catch(res => { Modal.error({ content: '로그인 처리중 오류가 발생하였습니다.' }); });

								// 이메일미등록인경우 회원가입페이지 컨펌노출
							} else {
								Modal.confirm({
									title: '회원가입이 필요합니다.',
									icon: <ExclamationCircleOutlined />,
									content: '개인정보 보호를 위해 이메일/비밀번호 정보가 필요합니다. 설정을 위해 회원가입페이지로 이동하시겠습니까? ',
									onOk: () => { this.props.history.push('/join') },
									onCancel: () => {
										localStorage.access_token = '';
										localStorage.refresh_token = '';
										this.props.history.push('/login');
									}
								})
							}
						}
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => { Modal.error({ content: '로그인 처리중 오류가 발생하였습니다.' }); });
		}

		this.handleInput = this.handleInput.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleAgree = this.handleAgree.bind(this);
		this.handleTimeout = this.handleTimeout.bind(this);
		this.handleAgreeBack = this.handleAgreeBack.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		$("#phone").show().stop().animate({ 'margin-left': 0, opacity: 1 }, 500);
	}
	handleTimeout() {
		this.setState({ msgTimeout: true }, () => console.log('msg timeout!', this.state));
		return false;
	}

	handleAgree(isAddAgree) {
		// 앱회원 등록
		// 기본 PUSH
		this.props.client.query({
			query: PUSH_SETTING_GQL,
			variables: { gongId: process.env.REACT_APP_SERVICE_GID },
			fetchPolicy: 'network-only',
		}).then(() => {
			// 마케팅수신동의
			this.props.client
				.mutate({
					mutation: SET_SHOP_PUSH_MUTATION_GQL,
					variables: { shopId: 0, agree: isAddAgree }
				}).then((data) => {
					if (!data.data.memberAgree.result) {
						gqlErrorHandler(data.data.memberAgree.msg);
					}

					// 로그인처리후 replace
					saveLoginMember(this.state.member.id, this.state.member.nick, this.state.member.email, this.state.member.mobile);

				})
				.catch((err) => { gqlErrorHandler(err) });

		}).catch((err) => { gqlErrorHandler(err) });


	}

	handleInput(mode, value) {
		let values = this.state.values;
		Object.keys(values).map((key) => key === mode ? values[key] = value : null);
		this.setState({ values: values });
	}

	handleSend(e) {
		e.preventDefault();
		let values = this.state.values;
		if (!values.mobile) return;
		axios.get(`${process.env.REACT_APP_SERVER_REST}/v2/api/member/phone/send?app=MARKET&phone=${values.mobile}`)
			.then(res => {
				if (res.data.result) {
					Modal.success({ content: '인증번호를 전송했습니다.' });
					// 인증번호가 전송되었을 경우 전송된 휴대폰번호를 저장
					values.sendedMobile = values.mobile;
					this.setState({ values: values, msgTimeout: false, msgSendDate: new Date().getTime() }, () => console.log('send MSG', this.state));
				} else {
					Modal.warning({ title: res.data.msg });
				}
			})
			.catch(res => { Modal.error({ content: '인증번호 전송중 오류가 발생하였습니다.' }) });
	}

	handleSubmit(e) {
		e.preventDefault();
		let values = this.state.values;
		if (values.sendedMobile && values.confirmNo) {
			const REDIRECT_URI = encodeURIComponent(process.env.REACT_APP_SERVICE_URI + window.location.pathname);
			axios
				.get(`${process.env.REACT_APP_SERVER_REST}/v2/api/member/phone/auth?phone=${values.sendedMobile}&auth=${values.confirmNo}&redirect_uri=${REDIRECT_URI}`)
				.then(res => {
					// 인증성공
					if (res.data.result) {
						localStorage.client_id = res.data.client_id;
						localStorage.client_secret = res.data.client_secret;
						localStorage.confirmed_mobile = values.sendedMobile;
						localStorage.confirmed_email = res.data.email ? res.data.email : '';

						window.location.replace(`${process.env.REACT_APP_SERVER_REST}/v2/oauth/member/authorize?client_id=${res.data.client_id}&redirect_uri=${REDIRECT_URI}&response_type=code`);

						// 인증실패
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => { Modal.error({ content: '인증번호 전송중 오류가 발생하였습니다.' }) });
		}
	}
	handleAgreeBack(e) {
		e.preventDefault();
		this.setState({ toAgree: false });
	}

	render() {
		if (this.state.toAgree) {
			return (<AgreeTerms onAgree={this.handleAgree} onBack={this.handleAgreeBack} />)
		} else {
			const onClassSubmit = this.state.values.sendedMobile && this.state.values.confirmNo ? 'on' : '';
			const onClassSendmsg = this.state.values.mobile ? 'on' : '';
			return (
				<>
					<div id="wrap">
						<div id="header" className="subHeader1">
							<div className="header">
								<div className="btnList">
									<a href="#this" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} className="txtHide">뒤로가기</a>
								</div>
								<Route exact path='/mobile-confirm/login'>
									<h1>로그인</h1>
								</Route>
								<Route exact path='/mobile-confirm/join'>
									<h1>휴대폰 인증</h1>
								</Route>
							</div>
						</div>
						<div id="container">
							<div id="contents" className="subCnts2">
								<div className="cmLoginBox">
									<Route exact path='/mobile-confirm/login'>
										<div className="tabUi">
											<ul>
												<li><Link to="/login" replace><button data-conts="email">이메일 로그인</button></Link></li>
												<li><button className="on" data-conts="phone">휴대폰 로그인</button></li>
											</ul>
										</div>
									</Route>
									<div className="loginSectoin">
										<div className="tabSectoin phone" id="phone" style={{ display: 'block' }}>
											<ul>
												<li className="telLi">
													<div className="inputSt1">
														<InputDelButton type="tel" value={this.state.values.mobile} className="clearInput" name="mobile" placeholder="휴대폰번호 입력" onInput={this.handleInput} />
														<div className="inputFocus"></div>
													</div>
													<a href="#this" className={`btnTy4  ${onClassSendmsg}`} onClick={this.handleSend}>{!this.state.values.sendedMobile ? "발송" : "재발송"}</a>
												</li>
												<li className="numLi">
													<div className="inputSt1">
														<InputDelButton type="tel" name="confirmNo" className="clearInput" placeholder="인증번호 입력" onInput={this.handleInput} maxLength={4} />
														<div className="inputFocus"></div>
														{this.state.values.sendedMobile && <Timer onTimeout={this.handleTimeout} startTime={this.state.msgSendDate} />}
													</div>
												</li>
											</ul>
											<p className="tipP">* 인증번호는 카카오톡으로 발송되며, 발송 실패 시 SMS로 발송 됩니다.</p>
											<div className="btnCenter">
												{this.state.msgTimeout === false ?
													<a href="#this" className={`btnTy2 ${onClassSubmit}`} onClick={this.handleSubmit}>확인</a>
													:
													<a href="#this" className={`btnTy2`} onClick={(e) => e.preventDefault()}>확인</a>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)
		}
	}
}));
