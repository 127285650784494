import React from "react";
import { createDaumPostcode } from './Library'
import { withRouter } from "react-router-dom";

class SearchAddress extends React.Component {
	constructor(props) {
		super(props);
		this.textInput = React.createRef();
		window.history.pushState(null, null, window.location.href)
	}
	componentDidMount() {
		createDaumPostcode({ id: 'daum-postcode-wrap' }, this.handlePickAddr, this.handleSearch)
		window.addEventListener('popstate', () => window.close()) // 다음api으로 인해 goback이 문제되므로, 새창으로 띄웠다가 '뒤로가기' 시 닫아버린다.
	}
	componentWillUnmount() {
		document.head.removeChild(document.getElementById('daum-postcode-script'))
	}
	handlePickAddr = (data) => {
		localStorage.setItem('mapAddr', JSON.stringify(data))
		window.opener.location.href = '/mypage/set-address/map'
		window.close()
	}
	handleSearch = (data) => {
		//console.log(data)
		window.history.pushState(data, null, window.location.href)
	}
	render() {
		return (
			<div id="wrap">
				<div id="header" className="subHeader2">
					<div className="header">
						<div className="btnList" onClick={(e) => { e.preventDefault(); this.props.history.goBack() }}><a href="#this" className="txtHide">뒤로가기</a></div>
						<h1>내 주소 설정</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents">
						<div className="myAdrSetup">
							<div id="daum-postcode-wrap" style={{ width: '100%', height: '100%' }}></div>
							<a href="#this" onClick={(e) => this.props.handleLocationToMap(e, () => { window.opener.location.href = '/mypage/set-address/map'; window.close(); })} className="btnLocalAdr">현재 위치로 주소 설정</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default withRouter(SearchAddress)