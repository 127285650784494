import React from "react";
import { useMutation, useQuery } from '@apollo/client'
import { isLogin } from './Library'
import Modal from './CustomModal'
import { loader, gql } from 'graphql.macro'

const setZzimGql = loader('../gql/set_zzim.gql')
const getGoodsListGql = loader('../gql/goods_list.gql')
const formatter = new Intl.NumberFormat()

const getIsMyZzimGql = gql`
	query getIsMyZzim($goodsId: Int!) {
		memberGoodsZzim(goodsId: $goodsId)
	}
`
export default function (props) {
	const forceMine = props.forceMine // 찜목록등

	const { data: dataCheckMyZzim } = useQuery(getIsMyZzimGql, { variables: { goodsId: props.goods.pk }, fetchPolicy: 'cache-and-network', skip: forceMine }) // 리스트에서 여러번 호출해도 알아서 한번만 요청됨.

	const isMyZzim = () => {
		return dataCheckMyZzim?.memberGoodsZzim || forceMine
	}

	const refetchQueries = forceMine ? [] : [ // 찜목록등 무조건 나의찜일때 현재 리페치할필요 없음
		{
			query: getIsMyZzimGql, // 나의찜갱신
			variables: { goodsId: props.goods.pk }
		},
		{
			query: getGoodsListGql,// 메뉴찜수갱신
			variables: { pk: props.goods.pk }
		}
	]

	const [setZzim] = useMutation(setZzimGql, { refetchQueries })

	const handleZzim = async (e) => {
		e.preventDefault()

		if (!isLogin()) {
			Modal.alert({ content: '로그인이 필요합니다.' });
			return
		}

		const doNext = await (forceMine // 찜목록 컨펌창 기다림
			? new Promise((resolve) =>
				Modal.confirm({
					title: "찜한 상품을 삭제 하시겠습니까?",
					onOk: () => resolve(true),
					onCancel: () => resolve(false)
				}))
			: true
		)

		if (doNext !== true) return

		const res = await setZzim({
			variables: {
				goodsid: props.goods.pk,
				mode: isMyZzim() ? 'remove' : 'add'
			}
		})

		if (res.data.setMemberZzim.result !== true) return

		if (forceMine) {
			props.handleUnset && props.handleUnset() // props.handleUnset?.()
		}
	}

	return (
		<a href="#this" onClick={handleZzim} className={`likeSt ${isMyZzim() ? 'on' : ''}`}>
			{formatter.format(props.goods.zzimCount)}
		</a >
	)
}