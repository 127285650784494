import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, useRouteMatch, NavLink } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { gqlErrorHandler } from './Library'
import { useQuery } from '@apollo/client';
import BottomScrollListener from 'react-bottom-scroll-listener';
import $ from 'jquery';
import { withApolloClient } from './ApolloClient';
import { Query } from '@apollo/client/react/components';
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const FAQS_QUERY = loader('../gql/faq_list.gql');
const FAQ_QUERY = loader('../gql/faq.gql');
const categoryClass = {
	'ORDER': 'type2',
	'ETC': 'type3',
	'MEMBER': 'type6',
	'PAY': 'type7',
	'SERVICE': 'type8',
	'SHIPPING': 'type10'
};

const Faq = (props) => {
	let match = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${match.path}`} component={FaqList} />
			<Route path={`${match.path}/view/:id`} component={FaqView} />
		</Switch>
	)
}

function FaqList(props) {
	const [category, setCategory] = useState("");
	const [title, setTitle] = useState("");
	const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />
	const doFetchMore = useRef(true);

	useEffect(() => {
		window.scrollTo(0, 0);
		$(".tabSectoin").css({ display: 'none', marginLeft: 30, opacity: 0 });
		$(".tabSectoin").show().stop().animate({ 'margin-left': 0, opacity: 1 }, 500);
	}, [category]);

	const searchVar = {
		gongId: process.env.REACT_APP_SERVICE_GID,
		category: category,
		title: title
	};

	const handleFetchMore = (fetchMore, data) => {
		if (!data || data.gongFaqs.pageInfo.hasNextPage === false) return
		if (doFetchMore === false) return

		doFetchMore.current = false;

		searchVar.cursor = data.gongFaqs.pageInfo.endCursor;
		fetchMore({
			variables: searchVar,
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongFaqs.edges;
				const pageInfo = fetchMoreResult.gongFaqs.pageInfo;
				return newEdges.length
					? {
						gongFaqs: {
							__typename: previousResult.gongFaqs.__typename,
							edges: [...previousResult.gongFaqs.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		}).then(() => doFetchMore.current = true);
	}

	const { error, data, fetchMore } = useQuery(FAQS_QUERY, { variables: searchVar, fetchPolicy: 'network-only' });
	//useBottomScrollListener(() => handleFetchMore(fetchMore, data));
	if (error) { gqlErrorHandler(error); return null; }

	return (
		<div id="wrap">
			<div id="header" className="subHeader1">
				<div className="header">
					<div className="btnList">
						<NavLink to="/mypage" className="txtHide">뒤로가기</NavLink>
					</div>
					<h1>문의하기</h1>
				</div>
			</div>
			<div id="container">
				<div id="contents">
					<div className="tabUi2">
						<ul>
							<li><NavLink to="/mypage/faq"><button className="on" data-conts="faq" data-btn="sch">FAQ</button></NavLink></li>
							<li><NavLink to="/mypage/inquiry"><button data-conts="oneTo" data-btn="btns">1:1 문의</button></NavLink></li>
						</ul>
					</div>
					<div className="comSchGp">
						<div className="comSch1 faqBtns">
							<div className="sch">
								<input type="search" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="검색어를 입력해주세요" />
								<a href="#this" className="txtHide btnSch">검색</a>
							</div>
						</div>
						<div className="tabSt2 faqBtns">
							<ul>
								<li><button className={category === "" ? "on" : ""} onClick={() => setCategory('')}>전체</button></li>
								<li><button className={category === "MEMBER" ? "on" : ""} onClick={() => setCategory('MEMBER')}>회원가입/로그인</button></li>
								<li><button className={category === "ORDER" ? "on" : ""} onClick={() => setCategory('ORDER')}>주문/배달</button></li>
								<li><button className={category === "PAY" ? "on" : ""} onClick={() => setCategory('PAY')}>결제/취소/환불</button></li>
								<li><button className={category === "SERVICE" ? "on" : ""} onClick={() => setCategory('SERVICE')}>서비스 이용</button></li>
								<li><button className={category === "ETC" ? "on" : ""} onClick={() => setCategory('ETC')}>기타</button></li>
							</ul>
						</div>
					</div>
					<div className="inquiryList">
						<div className="tabSectoin on">
							<div className="faq noticeAlarm">
								<ul>
									{data?.gongFaqs.edges.length > 0
										? <BottomScrollListener onBottom={() => handleFetchMore(fetchMore, data)} triggerOnNoScroll={false} debounceOptions={{ leading: false }} debounce={500} >

											{data?.gongFaqs.edges.map((v, k) => (
												<li key={k}>
													<a href={"/mypage/faq/view/" + v.node.pk} className="alTit">
														<h4 className={`typeSym ${categoryClass[v.node.category]}`}>{v.node.categoryName}</h4>
														<p>{v.node.title}</p>
													</a>
												</li>
											))}
										</BottomScrollListener> : <li className="noData">
											<p>등록된 문의내역이 없습니다.</p>
										</li>
									}
								</ul>
								{data?.gongFaqs.pageInfo.hasNextPage === true &&
									<div style={{ paddingLeft: '48%', marginTop: 5 }}>
										<Spin tip="" indicator={antIcon} />
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}

class FaqView extends React.Component {
	render() {
		const { id } = this.props.match.params;
		return (
			<div id="wrap">
				<div id="header" className="subHeader1">
					<div className="header">
						<div className="btnList">
							<NavLink to="/mypage/faq" className="txtHide btnHback">뒤로가기</NavLink>
						</div>
						<h1>문의하기</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents">
						<Query query={FAQ_QUERY} variables={{ pk: id }}>
							{({ loading, error, data }) => {
								if (loading) return null
								if (error) { gqlErrorHandler(error); return null }
								if (data.gongFaq === '') {
									return <></>
								}
								return (
									<div className="noticeView">
										<div className="thDiv">
											<h4 className={`typeSym ${categoryClass[data.gongFaq.category]}`}>{data.gongFaq.categoryName}</h4>
											<p>{data.gongFaq.title}</p>
											<span></span>
										</div>
										<div className="tdDiv" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: data.gongFaq.content }}></div>
									</div>
								)
							}}
						</Query>

					</div>
				</div>
			</div>
		)
	}
}

export default withApolloClient(Faq);